import React, { useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import styled from 'styled-components'
import _ from 'lodash'
import PreviewImageDailog from './../Layouts/PreviewImageDailog'
import { toggleNewPaymentModal } from '../../actions/utilityActions'
import { chargeByPrevious } from '../../actions/ccbillActions'
import { googleAnalyticsTrackEvent } from '../../utils/GoogleAnalyticsEvent'
import { loadMore } from '../../actions/chatActions'
import { getCloudFrontAssetsUrl } from '../../utils/assets'
import AddFundForm from '../crypto/AddFundForm'
import { chatContentPurchase } from '../../actions/stickyIoActions'
import { chatPurchasePayment } from '../../actions/hybridPaymentAction'
import { purchaseFromWallet, updateWalletAmount } from '../../actions/cryptoPaymentActions'
import ConfirmSweetAlert from '../Layouts/ConfirmSweetAlert'
import { setConfirmSweetAlert, setSweetAlert } from '../../actions/sweetAlertActions'
import SweetAlertsWrapper from '../Layouts/SweetAlertsWrapper'

const CommentDisplayStyle = styled.div`
    width: 100%;
    max-height: ${props => props.isStreamPublisher === true ? '400px' : '35%'};
    overflow-y: scroll !important;
    -webkit-overflow-scrolling: touch;
    position: fixed;
    bottom: ${props => props.role === 'live_stream_manager' ? '0' : '70px'};
    width: 100%;
    scrollbar-color: auto;
    scrollbar-width: thin;
    max-width: 100%;
    display: flex;
    flex-direction: column-reverse;
    
    .main_division {
        position: relative;
        word-break: break-word;
    }    
`

const MediaDiv = styled.div`
width: 100px;
background-color: #ffffff26;
text-align: center;
display: -webkit-inline-box;
height: 50px;
position: relative;
vertical-align: middle;
margin-bottom: 5px;
border-radius: 5px;
border: 1px solid #495057;
cursor: pointer;

    i {
        color: black;
        padding: 10px;
    }

`
const MessageTypeIcon = styled.div`
    position: absolute;
    top: 5px;
    right: 5px;
    color: #ffffff;
        i {
            margin: 0;
            padding: 0;
        }
`

const EllipsisDiv = styled.div`
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
`

function LiveStreamMessages(props) {
    const { card_background_color, site_font_color } = props.auth.appSettings
    const [showPreview, setShowPreview] = useState(false)
    const [galleryImages, setGalleryImages] = useState([])
    const [mediaUrl, setMediaUrl] = useState('')
    const [messageType, setMessageType] = useState('')
    const [pageNum, setPageNum] = useState(1)
    const [unlockData, setUnlockData] = useState({})
    const [showAddFundPopup, setShowAddFundPopup] = useState(false)
    const [remainAmount, setRemainAmount] = useState(0)
    const [id, setId] = useState('')
    const [unlockAmount, setUnlockAmount] = useState(5)
    const [productSKU, setProductSKU] = useState('')
    const [productName, setProductName] = useState('')
    const [title, setTitle] = useState('')
    const [showConfirmAlert, setConfirmAlert] = useState(false)
    const [media, setMedia] = useState([])

    const isAdmin = props.auth.user.isAdmin

    const scrollToBottom = () => {
        // get the messageLiveList divs and set the scrollTop to the height of the div
        const objDiv = document.getElementById('messageLiveList')
        if (objDiv) {
            objDiv.scrollTop = objDiv.scrollHeight
        }
    }

    const allMessages = useMemo(() => {
        let filteredMessages = props.chat.messages
        if (isAdmin === false) {
            filteredMessages = filteredMessages.filter(message => message.type !== 'system')
        }
        filteredMessages = filteredMessages.filter(message => message.type !== 'GO_LIVE_STREAM')
        filteredMessages = filteredMessages.reverse()
        scrollToBottom()
        return filteredMessages
    }, [props.chat.messages])


    let receiverId

    if (isAdmin) {
        if (props.match.params.id === undefined) {
            receiverId = props.chat.selectedUserId
        } else {
            receiverId = props.match.params.id
        }
    } else {
        receiverId = props.chat.selectedUserId
    }

    const receiverProfileDetails = (props.chat.userProfileInfo && props.chat.userProfileInfo[receiverId])
        ? props.chat.userProfileInfo[receiverId]
        : getCloudFrontAssetsUrl('faces/avatar.png')

    const setMessage = (message, type) => {
        if (type === 'text' || type === 'photo' || type === 'gallery' || type === 'video') {
            return message
        } else if (type === 'tips') {
            if (isAdmin === false) {
                return (
                    <>
                        <b style={{ backgroundColor: '#9cff884d' }} className='d-inline-block mt-1 p-1 px-3 rounded-pill'>You just tipped {receiverProfileDetails.name} {message}!</b>
                    </>)
            } else {
                return (
                    <>
                        <b style={{ backgroundColor: '#9cff884d' }} className='d-inline-block mt-1 p-1 px-3 rounded-pill'>{receiverProfileDetails.name} just tipped you {message}!</b>
                    </>)
            }
        } else if (type === 'system') {
            if (isAdmin === true) {
                if (message === 'join') {
                    return `${receiverProfileDetails.name} has entered the Go Live stream!`
                } else if (message === 'leave') {
                    return `${receiverProfileDetails.name} has left the Go Live stream!`
                } else if (message === 'start') {
                    return 'You have started the Go Live stream!'
                } else if (message === 'stop') {
                    return 'You have ended the Go Live stream!'
                }
            } else {
                if (message === 'join') {
                    return 'You have joined the Go Live stream!'
                } else if (message === 'leave') {
                    return 'You left the Go Live stream!'
                } else if (message === 'start') {
                    return `${receiverProfileDetails.name} has started the Go Live Stream!`
                } else if (message === 'stop') {
                    return `${receiverProfileDetails.name} has ended the Go Live Steam!`
                }
            }
        }
    }

    const userName = isAdmin === false ? props.auth.user.name : receiverProfileDetails.name
    const classes = { row: 'locked-row-6 m-auto w-100' }

    const LiveStreamPreviewMedia = (message) => {
        if (isAdmin === false && message.isLocked === 'locked') {
            unlockContent(message._id, message.amount, message.isMassMessage, message.type, message.message)
            return
        }
        setMessageType(message.type)
        if (message.type === 'gallery') {
            setMedia(message.media)
        } else {
            setMediaUrl(message.mediaUrl)
        }
        setShowPreview(true)
    }

    // Need to add new google events once merged with developer branch

    const unlockContent = (id, amount, isMassMessage, messageType, title) => {
        sendUnlockContentRequest(id, amount, isMassMessage, messageType, title)
    }

    const sendUnlockContentRequest = async (id, amount, isMassMessage, messageType, title) => {
        let productSKU = messageType
        if (productSKU === 'photo') {
            productSKU = 'image'
        }
        let productName = isMassMessage ? 'unlock mass' : 'unlock chat'
        // Set google analytics add_to_cart event for unlock mass or unlock chat
        googleAnalyticsTrackEvent('add_to_cart', '', amount, productSKU, productName, title)

        let confirmationMessage = `Please Confirm Your Purchase of $${amount}.`
        if (props.auth.user.default_payment_method === 'crypto_currency') {
            confirmationMessage += `You currently have $${props.auth.user.wallet_amount} in your wallet. Amount will be debited from your wallet balance.`
        }

        setId(id)
        setUnlockAmount(amount)
        setProductName(productName)
        setProductSKU(productSKU)
        setTitle(title)

        if (props.auth.user.default_payment_method === 'crypto_currency' && props.auth.user.wallet_amount < parseFloat(amount)) {
            processContentUnlock(amount)
        } else {
            setConfirmAlert(true)
            props.setConfirmSweetAlert({
                description: confirmationMessage
            })
        }
    }

    const processContentUnlock = async (amount = unlockAmount) => {
        let data = {
            messageId: id,
            amount: amount,
            recurring: false,
            email: props.auth.user.email,
            action: 'chat',
            productName: productName,
            productSKU: productSKU,
            productCategory: title
        }

        if (props.auth.user.default_payment_method === 'crypto_currency') {
            setUnlockData(data)
            unlockUsingCrypto(data)
            return
        } else {
            const payment_api_version = _.get(props.auth.appSettings, 'payment_api_version', 'v1')
            if (payment_api_version === 'v1') {
                if (props.auth.appSettings.is_sticky_io_enabled === true) {
                    props.chatContentPurchase(data)
                } else {
                    props.chargeByPrevious(data)
                }
            } else {
                await props.chatPurchasePayment(data)
            }
        }
    }

    const { isMoreMessageLoading, totalMessagesPage, currentMessagesPage } = props.chat
    const shouldShowLoadMoreButton = ((totalMessagesPage !== currentMessagesPage) && (props.chat.messages.length > 0)) ? true : false

    const loadMoreMessages = () => {
        let data = {
            userId: props.chat.selectedUserId,
            modelId: props.chat.selectedModelId,
            pageNum: pageNum + 1
        }
        props.loadMore(data, props.auth.user.isAdmin, () => {
            setPageNum(pageNum + 1)
        })
    }

    const unlockUsingCrypto = async (messageData) => {
        let userWalletAmount = props.auth.user.wallet_amount
        let showDefaultAlert = true
        if (_.isNumber(messageData) === true) {
            userWalletAmount = messageData
            messageData = unlockData
            showDefaultAlert = false
        }
        const messageAmount = Number(parseFloat(messageData.amount).toFixed(2))
        const data = {
            payment_for: 'chat',
            content_id: messageData.messageId,
            userId: props.auth.user._id
        }
        if (_.get(messageData, 'promotion_id', false) !== false) {
            data.promotion_id = messageData.promotion_id
            data.is_promotion_applied = messageData.is_promotion_applied
        }
        if (userWalletAmount >= messageAmount) {
            const res = await props.purchaseFromWallet(data)
            if (res.success === 1) {
                props.updateWalletAmount(res.data.wallet_balance)
                setUnlockData({})
                if (showDefaultAlert || res.data.wallet_balance === 0) {
                    props.setSweetAlert({ description: res.data.message })
                } else {
                    const description = `The payment of $${res.data.transaction_amount} was successful. Your content was unlocked. The remainder of your crypto deposit is stored in your wallet and can be used for future transactions.`
                    props.setSweetAlert({ description: description })
                }
            } else {
                const message = _.get(res, 'message', 'Payment failed.')
                props.setSweetAlert({ description: message })
            }
            return
        } else {
            if (userWalletAmount === 0.00) {
                setShowAddFundPopup(true)
                setRemainAmount(0)
            } else {
                const remainAmount = Math.ceil(messageAmount - userWalletAmount)
                setShowAddFundPopup(true)
                setRemainAmount(remainAmount)
            }
        }
    }

    const hideAddFundForm = () => {
        setShowAddFundPopup(false)
    }

    return (
        <>
            <CommentDisplayStyle
                isStreamPublisher={props.isPublisher}
                className='px-5' id='messageLiveList'
                role={props.auth.user.role}
            >
                {allMessages.map((message, index) => {
                    return (<div key={index} className='main_division'>
                        {message.type === 'system' ?
                            <div className='mb-2 text-center'>
                                <div className='card-text'>
                                    <div className='row align-items-center px-3'>
                                        <div className='col-12 muted' style={{ maxWidth: '100%' }}>
                                            {setMessage(message.message, message.type)}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            :
                            <>
                                {(isAdmin === message.fromAdmin) ?
                                    <div className='mb-2 text-right pl-sm-5'>
                                        <div className='card-text'>
                                            <div className='row align-items-center justify-content-end px-3'>
                                                <div className='col-sm-12 col-12 pr-0' style={{ maxWidth: '90%' }}>
                                                    <div className='row'>
                                                        <div className='col-12 muted'>
                                                            {message.fromAdmin ? '' : userName}
                                                        </div>
                                                        <div className='col-12'>
                                                            {message.type !== 'text' && message.type !== 'tips' ?
                                                                <>
                                                                    <MediaDiv onClick={() => LiveStreamPreviewMedia(message)}>
                                                                        {(message.isLocked === 'free' || isAdmin === true || message.isLocked === 'unlocked') &&
                                                                            <div className='m-auto' width='50px' height='50px'>
                                                                                <i className='fas fa-search'></i>
                                                                            </div>
                                                                        }
                                                                        {message.isLocked === 'locked' && isAdmin === false &&
                                                                            <div className='m-auto' width='50px' height='50px'>
                                                                                <i className='fas fa-lock'></i>
                                                                            </div>
                                                                        }
                                                                        <MessageTypeIcon>
                                                                            {message.type === 'video' &&
                                                                                <i className='fas fa-video' style={{ fontSize: '15px', width: '25px' }} />
                                                                            }
                                                                            {message.type === 'gallery' &&
                                                                                <i className='fas fa-images' style={{ fontSize: '15px', width: '25px' }} />
                                                                            }
                                                                            {message.type === 'photo' &&
                                                                                <i className='fas fa-image' style={{ fontSize: '15px', width: '25px' }} />
                                                                            }
                                                                        </MessageTypeIcon>
                                                                    </MediaDiv>
                                                                    <br />
                                                                    <EllipsisDiv className='text-bold'>
                                                                        {setMessage(message.message, message.type)}
                                                                    </EllipsisDiv>
                                                                </> :
                                                                <b>{setMessage(message.message, message.type)}</b>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div> :
                                    <div className='mb-2 text-left pr-sm-5'>
                                        <div className='card-text'>
                                            <div className='row align-items-center px-3'>
                                                <div className='col-sm-12 col-12 pl-0  text-left' style={{ maxWidth: '90%' }}>
                                                    <div className='row'>
                                                        <div className='col-12 muted'>
                                                            {message.fromAdmin ? '' : userName}
                                                        </div>
                                                        <div className='col-12'>
                                                            {message.type !== 'text' && message.type !== 'tips' ?
                                                                <>
                                                                    <MediaDiv onClick={() => LiveStreamPreviewMedia(message)}>
                                                                        {(message.isLocked === 'free' || isAdmin === true || message.isLocked === 'unlocked') &&
                                                                            <div className='m-auto' width='50px' height='50px'>
                                                                                <i className='fas fa-search'></i>
                                                                            </div>
                                                                        }
                                                                        {message.isLocked === 'locked' && isAdmin === false &&
                                                                            <div className='m-auto' width='50px' height='50px'>
                                                                                <i className='fas fa-lock'></i>
                                                                            </div>
                                                                        }
                                                                        <MessageTypeIcon>
                                                                            {message.type === 'video' &&
                                                                                <i className='fas fa-video' style={{ fontSize: '15px', width: '25px' }} />
                                                                            }
                                                                            {message.type === 'gallery' &&
                                                                                <i className='fas fa-images' style={{ fontSize: '15px', width: '25px' }} />
                                                                            }
                                                                            {message.type === 'photo' &&
                                                                                <i className='fas fa-image' style={{ fontSize: '15px', width: '25px' }} />
                                                                            }
                                                                        </MessageTypeIcon>
                                                                    </MediaDiv>
                                                                    <br />
                                                                    <EllipsisDiv className='text-bold'>
                                                                        {setMessage(message.message, message.type)}
                                                                    </EllipsisDiv>
                                                                </> :
                                                                <b>{setMessage(message.message, message.type)}</b>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }
                            </>
                        }
                    </div>
                    )
                })
                }
                {shouldShowLoadMoreButton ? <div className='text-center'>
                    {isMoreMessageLoading ?
                        <div className='text-center' style={{ right: '0', left: 'auto' }}>
                            <div className='spinner-border' role='status' style={{ color: '#000000' }}>
                                <span className='sr-only'>Loading...</span>
                            </div>
                        </div> :
                        <button className='btn' onClick={loadMoreMessages}
                            style={{
                                backgroundColor: props.auth.appSettings.content_color,
                                color: props.auth.appSettings.content_font_color
                            }}
                        >
                            Load older messages
                        </button>
                    }
                </div> : <></>}
            </CommentDisplayStyle>
            {showPreview === true ?
                <PreviewImageDailog
                    media={media}
                    galleryImages={galleryImages}
                    blogVideoUrl={mediaUrl}
                    blogUrl={mediaUrl}
                    updateIsBlogOpen={() => setShowPreview(false)}
                    classes={classes}
                    type={messageType}
                    isBlogOpen={showPreview}
                    contentFrom='liveStream'
                />
                : null
            }
            {showAddFundPopup === true &&
                <div className='modal fade show' role='dialog' style={{ display: 'block', backgroundColor: '#00000080' }}>
                    <div className='modal-dialog modal-dialog-centered modal-dialog-scrollable' role='document'>
                        <div className='modal-content' style={{ backgroundColor: card_background_color }}>
                            <div className='modal-header align-items-center'>
                                <button className='close' onClick={() => {
                                    setShowAddFundPopup(false)
                                    setRemainAmount(0)
                                }} style={{ color: site_font_color, opacity: 1 }} />
                            </div>
                            <div className='modal-body'>
                                <div className='container'>
                                    <AddFundForm
                                        onHideAddFund={hideAddFundForm}
                                        type='chat'
                                        transactionAmount={Number(unlockData.amount)}
                                        remainAmount={remainAmount}
                                        onCompleteTransaction={
                                            (updatedBalance) => {
                                                setShowAddFundPopup(false)
                                                if (updatedBalance) {
                                                    unlockUsingCrypto(updatedBalance)
                                                }
                                            }
                                        }
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
            {showConfirmAlert && <ConfirmSweetAlert onConfirm={() => { processContentUnlock(); setConfirmAlert(false) }} onCancel={() => setConfirmAlert(false)} />}
            <SweetAlertsWrapper />
        </>
    )
}

LiveStreamMessages.propTypes = {
    chat: PropTypes.object.isRequired,
    auth: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    toggleNewPaymentModal: PropTypes.func.isRequired,
    chargeByPrevious: PropTypes.func.isRequired,
    loadMore: PropTypes.func.isRequired,
    isPublisher: PropTypes.bool.isRequired,
    chatContentPurchase: PropTypes.func.isRequired,
    chatPurchasePayment: PropTypes.func.isRequired,
    purchaseFromWallet: PropTypes.func.isRequired,
    updateWalletAmount: PropTypes.func.isRequired,
    setConfirmSweetAlert: PropTypes.func.isRequired,
    setSweetAlert: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
    chat: state.chat,
    auth: state.auth
})

export default connect(mapStateToProps, {
    toggleNewPaymentModal,
    chargeByPrevious,
    loadMore,
    chatContentPurchase,
    chatPurchasePayment,
    purchaseFromWallet,
    updateWalletAmount,
    setConfirmSweetAlert,
    setSweetAlert
})(withRouter(LiveStreamMessages))

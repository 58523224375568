import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { isIOS } from 'react-device-detect'
import OverlayContainer from './OverlayStyle'
import _ from 'lodash'
import { v4 as uuidv4 } from 'uuid'

const ModalStyle = styled.div`
    width: 100%;
    z-index: 9999 !important;

    .modal {
        display: block;
        background-color: #000000e6;
        width: ${props => props.width};
        height: ${props => props.height};
        left: ${props => props.offsetX}px;
        top: ${props => props.offsetY}px;
    }

    video::-webkit-media-controls-fullscreen-button {
        display: none !important;
    }

    .d-flex.flex-column.justify-content-center.align-items-center {
        padding-right: 50px;
        padding-left: 50px;
    }

    video {
        max-width: 100% !important;
    }

    img {
        object-fit: contain;
    }

    .media-popup {
        height: 100vh;
    }

    video, img {
        max-height: var(--app-height);
    }

    .content {
        height: 100% !important;
        overflow-y: hidden;
    }

    @media(max-width: 767px) {
        .d-flex.flex-column.justify-content-center.align-items-center {
            padding-right: 0;
            padding-left: 0;
        } 
        
        video, img {
            max-height: ${props => props.isIOS ? 'var(--app-height)' : '90vh'};
        }

        .media-popup {
            height: ${props => props.isIOS ? 'var(--app-height)' : '90vh'} !important;
        }
    }
`

const CloseButton = styled.button`
    background-color: ${props => props.content_color} !important; 
    color: ${props => props.content_font_color} !important;
    border-radius: 77%;
    height: 30px;
    width: 30px;
    right: 10px; 
    position: absolute; 
    top: 20px;
    cursor: pointer;
    z-index: 10;
    display: inherit;
    align-items: center;
    justify-content: center;
    overflow: hidden;
`

function FullScreenModelPopUpDialog(props) {
    const userId = props.auth.user._id
    const showWatermark = _.get(props, 'showWatermark', true)
    const width = props.reqFrom === 'popup' ? '100dvw' : '100%'
    const height = props.reqFrom === 'popup' ? '100dvh' : '100%'
    const offsetX = props.reqFrom === 'popup' ? '-' + props.offsetX : '0'
    const offsetY = props.reqFrom === 'popup' ? '-' + props.offsetY : '0'

    const popupContent = () => {
        return <div className='content-modal'>
            {
                props.type === 'photo' && props.url !== '' &&
                <img className='' draggable={false} src={props.url} alt='not found' />
            }
            {
                props.type === 'video' && props.url !== '' && <>
                    <video id='myVideo' key={props.url} autoPlay webkit-playsinline playsInline src={props.url} controls controlsList='nodownload' />
                </>
            }
        </div>
    }

    useEffect(() => {
        appHeight()
        window.addEventListener('resize', appHeight)
        window.addEventListener('mousedown', onClickOutSideContent)
        return () => {
            window.removeEventListener('mousedown', onClickOutSideContent)
        }
    }, [])

    useEffect(() => {
        const close = (event) => {
            (event.keyCode === 27) && props.handleClose()
        }
        window.addEventListener('keydown', close)
        return () => {
            window.removeEventListener('keydown', close)
        }
    }, [])

    const appHeight = () => {
        const doc = document.documentElement
        doc.style.setProperty('--app-height', `${window.innerHeight}px`)
    }

    const onClickOutSideContent = (e) => {
        let node = e.target
        let inside = false
        while (node) {
            if (node.classList.contains('content-modal')) {
                inside = true
                break
            }
            node = node.parentElement
        }
        if (!inside) {
            props.handleClose()
        }
    }

    return <ModalStyle className='media-popup' isIOS={isIOS} width={width} offsetX={offsetX} offsetY={offsetY} height={height}>
        <div className='modal fade show' key={uuidv4()}>
            <div className="modal-dialog modal-fullscreen" style={{ height: '100%', width: '100%', margin: '0%', maxHeight: '100%', maxWidth: '100%' }}>
                <div className="modal-content" style={{ backgroundColor: 'transparent' }}>
                    <div className="modal-body" style={{ backgroundColor: 'transparent', margin: '0', padding: '0' }}>
                        <CloseButton
                            className='close'
                            content_color={props.auth.appSettings.content_color}
                            content_font_color={props.auth.appSettings.content_font_color}
                            onClick={() => {
                                if (props.type === 'video') {
                                    document.querySelectorAll('.myVideoPause').forEach(vid => vid.pause())
                                }
                                props.handleClose()
                            }}
                        />
                        <div className='content d-flex flex-column justify-content-center align-items-center media-popup'>
                            <div className='content-modal'>
                                {showWatermark === true ?
                                    <OverlayContainer userId={userId}>{popupContent()}</OverlayContainer>
                                    : popupContent()
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </ModalStyle>
}

FullScreenModelPopUpDialog.propTypes = {
    auth: PropTypes.object.isRequired,
    type: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    handleClose: PropTypes.func.isRequired,
    children: PropTypes.object,
    classes: PropTypes.string,
    showWatermark: PropTypes.bool,
    // TODO: remove below props
    reqFrom: PropTypes.bool,
    offsetX: PropTypes.number,
    offsetY: PropTypes.number
}

const mapStateToProps = state => ({
    auth: state.auth
})

export default connect(mapStateToProps)(withRouter(FullScreenModelPopUpDialog))

import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import ChatInputOnGroupLive from '../chat-new/ChatInputOnGroupLive'
import LiveStreamGroupMessages from './LiveStreamGroupMessages'
import styled from 'styled-components'
import WebsiteFontColor from '../Layouts/WebsiteFontColor'
import socket from '../../utils/socket'
import ModalPopUp from '../Layouts/ModalPopUp'
import { isIPad13 } from 'react-device-detect'
import Stopwatch from '../timer/Stopwatch'
import { getCloudFrontAssetsUrl } from '../../utils/assets'

const LiveStreamPageMainDiv = styled.div`
    background-color: black;
    max-width: 100%;
    overflow: hidden;
    max-height: -webkit-fill-available;
    position: absolute;
`

const VideoBanner = styled.section`
    width: 100%;
    padding: 0px;
    box-sizing: border-box;
    margin-bottom: -6px;
    position: fixed;
    max-height: -webkit-fill-available;
    background: #000;
    .user-list-modal-row:last-child {
        border: none !important;
        margin-bottom: 0 !important;
        padding-bottom: 0 !important;
    }

    video {
        min-height: 100vh;
        max-height: 100vh;
        object-fit: cover;
        overflow: hidden;
        max-width: 100%;
        min-width: 100%;
    }

    .group-live-stream-main-div {
        width: 100%;
        position: unset;
        height: 100vh;
        background-repeat: no-repeat;
        background-position-x: center;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
    }
`

const CommentSection = {
    position: 'fixed',
    bottom: '0',
    left: '0',
    right: '0'
}

const LiveCloseSection = {
    position: 'absolute',
    display: 'flex',
    zIndex: '5',
    top: '4px',
    width: '100%'
}

const BtnStyle = {
    backgroundColor: '#0000004d',
    padding: '5px !important',
    borderRadius: '25px',
    width: '40px',
    height: '40px',
    margin: '0'
}

const SwitchCameraBtnStyle = {
    backgroundColor: '#0000004d',
    padding: '0px 9px',
    borderRadius: '25px',
    width: '40px',
    height: '40px',
    margin: '0'
}

const HorizontalUl = styled.ul`
width: 100%;
list-style-type: none;
margin: 0;
padding: 0;
overflow: hidden;
line-height: 50px;
    li {
        float: left;
        margin: auto 6px;
    }
    .userNameTranculate{
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: calc(40vw);
        display: block;
    }
    @media(max-width: 768px){
        .userNameTranculate{max-width: calc(30vw);}
    }
    @media(max-width: 480px){
        .userNameTranculate{max-width: calc(25vw);}
    }
    @media(max-width: 425px){
        .userNameTranculate{max-width: calc(25vw);}
    }
    @media(max-width: 400px){
        .userNameTranculate{max-width: calc(15vw);}
    }
    @media(max-width: 330px){
        .userNameTranculate{max-width: calc(13vw);}
    }
`

const Image = styled.img`
    background-color: lightgray;
    height: 40px;
    border-radius: 20px;
`

const Icons = styled.div`
    width: 20px;
    height: 20px;
    background-color: ${props => props.color};
    mask: url(${props => props.image});
    -webkit-mask: url(${props => props.image}) no-repeat center / contain;
`

const TimerLi = styled.li`
    @media(max-width: 376px) {
        margin: 0px !important;
        padding-bottom: 8px;
        display: flex;
    }
`

const MediaSelection = styled.div`
    width: fit-content;
`

function GroupLiveStreamModal(props) {

    const [isMuted, setIsMuted] = useState(true)
    const showVideoType = window.innerWidth >= 1024 && props.isPublisher === false ? 'contain' : 'cover'
    const [containOrCover, setContainOrCover] = useState(showVideoType)
    const [showMirrorVideo, setShowMirrorVideo] = useState(true)
    const [isOverlayApply, setIsOverlayApply] = useState(true)
    const [overlayPercentage, setOverlayPercentage] = useState(10)
    const [viewer, setViewer] = useState(0)
    const [totalTipAmount, setTotalTipAmount] = useState(0)
    const [showLiveUserList, setShowLiveUserList] = useState(false)
    const [viewerList, setViewerList] = useState([])
    const [selectedVideoInputId, setSelectedVideoInputId] = useState('')
    const [selectedAudioInputId, setSelectedAudioInputId] = useState('')

    useEffect(() => {
        if (props.auth.user.role !== 'model' && props.auth.user.role !== 'proxy_user' && props.auth.user.role !== 'live_stream_manager') {
            socket.emit('GET_VIEWER')
        }
        loadSocketEvent()
    }, [])

    const loadSocketEvent = () => {
        socket.on('SET_VIEWER', (data) => {
            setViewer(data.count)
            setViewerList(data.userList)
        })
        if (props.auth.user.role === 'model' || props.auth.user.role === 'live_stream_manager') {
            socket.on('SET_TIP_AMOUNT', (amount) => {
                setTotalTipAmount(amount)
            })
        }
    }

    const isAdmin = props.auth.user.isAdmin

    let receiverId

    if (isAdmin) {
        if (props.match.params.id === undefined) {
            receiverId = props.chat.selectedUserId
        } else {
            receiverId = props.match.params.id
        }
    } else {
        receiverId = props.chat.selectedUserId
    }

    const receiverProfileDetails = (props.chat.userProfileInfo && props.chat.userProfileInfo[receiverId])
        ? props.chat.userProfileInfo[receiverId]
        : getCloudFrontAssetsUrl('faces/avatar.png')

    const startVideoOnAlert = () => {
        props.video.current.play()
    }

    const onChangeOverlay = () => {

        if (isOverlayApply === false) {
            setIsOverlayApply(true)
            return
        }

        if (overlayPercentage === 30) {
            setOverlayPercentage(10)
            setIsOverlayApply(false)
        } else {
            setOverlayPercentage(overlayPercentage + 10)
        }
    }

    const userName = isAdmin === false ? props.auth.user.name : receiverProfileDetails.name

    const {
        card_background_color
    } = props.auth.appSettings

    const onShowUserList = () => {
        if (viewerList.length > 0) {
            setShowLiveUserList(true)
        }
    }

    const handleSelectChange = (event) => {
        const { name, value } = event.target
        if (name === 'mic') {
            const videoInputId = selectedVideoInputId ? selectedVideoInputId : props.videoInputList[0].deviceId
            props.onSwitchCamera(videoInputId, value)
            setSelectedAudioInputId(value)
        } else if (name === 'cam') {
            const audioInputId = selectedAudioInputId ? selectedAudioInputId : props.audioInputList[0].deviceId
            props.onSwitchCamera(value, audioInputId)
            setSelectedVideoInputId(value)
        }
    }

    return (
        <>
            {props.loading === false || isAdmin === true ?
                <LiveStreamPageMainDiv>
                    <VideoBanner>
                        <div>
                            <video className='janus-player-video'
                                id='myVideo'
                                autoPlay
                                playsInline
                                ref={props.video}
                                controls={false}
                                muted={isMuted}
                                style={{
                                    maxHeight: '100vh',
                                    height: '100%',
                                    objectFit: containOrCover,
                                    backgroundPosition: 'center',
                                    maxWidth: '100vw',
                                    width: '100%',
                                    // margin: '0 12px',
                                    borderRadius: '0',
                                    transform: `${props.isPublisher && isIPad13 === false && showMirrorVideo ? 'scale(-1, 1)' : 'scale(1)'}`,
                                    minHeight: containOrCover === 'contain' ? '92vh' : '100vh'
                                }}
                            />
                        </div>
                        <div className='group-live-stream-main-div'>
                            <div className='row align-items-center mx-auto' style={LiveCloseSection}>
                                <div className='col-10 text-left d-inline-block'>
                                    <HorizontalUl>
                                        <li style={{ marginLeft: '0' }}>
                                            <h4 className='mb-0 d-inline-block'><span className='badge badge-danger'
                                                style={{
                                                    paddingRight: '0.4rem',
                                                    paddingTop: '0.3rem',
                                                    paddingBottom: '0.35rem',
                                                    fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif'
                                                }}>LIVE</span>
                                            </h4>
                                        </li>
                                        {props.isPublisher === false &&
                                            <li>
                                                {isMuted === false ?
                                                    <button className='btn shadow-none py-0 text-light'
                                                        style={{
                                                            padding: '0px',
                                                            backgroundColor: '#0000004d',
                                                            borderRadius: '25px',
                                                            width: '40px',
                                                            height: '40px',
                                                            margin: '0'
                                                        }} onClick={() => setIsMuted(true)}><i className='fas fa-volume-up'></i></button>
                                                    :
                                                    <button className='btn shadow-none py-0 text-light' style={BtnStyle} onClick={() => setIsMuted(false)}><i className='fas fa-volume-mute'></i></button>
                                                }
                                            </li>
                                        }
                                        {(props.isPublisher === true && props.isMobileDevice) &&
                                            <li>
                                                {props.isPublisherMuted === false ?
                                                    <button className='btn shadow-none py-0 text-light' style={BtnStyle} onClick={() => props.onMute()}><i className='fas fa-microphone'></i></button>
                                                    :
                                                    <button className='btn shadow-none py-0 text-light'
                                                        style={{
                                                            padding: '7px',
                                                            backgroundColor: '#0000004d',
                                                            borderRadius: '25px',
                                                            width: '40px',
                                                            height: '40px',
                                                            margin: '0'
                                                        }} onClick={() => props.onUnmute()}><i className='fas fa-microphone-slash text-danger'></i></button>
                                                }
                                            </li>
                                        }
                                        <li>
                                            {containOrCover === 'contain' ?
                                                <button className='btn shadow-none py-0 text-light' style={BtnStyle} onClick={() => setContainOrCover('cover')}><i className='fas fa-expand-arrows-alt'></i></button>
                                                :
                                                <button className='btn shadow-none py-0 text-light' style={BtnStyle} onClick={() => setContainOrCover('contain')}><i className='fas fa-expand'></i></button>
                                            }
                                        </li>
                                        {props.isPublisher === true && totalTipAmount > 0 &&
                                            <li className='align-items-center badge font-weight-bold my-3 px-2 py-2 rounded-pill'
                                                style={{ fontFamily: 'Roboto', backgroundColor: 'rgb(34 174 5)' }}>$ {totalTipAmount}</li>
                                        }
                                        <li>
                                            <b className='mb-0 userNameTranculate'>
                                                <span>{userName}</span>
                                            </b>
                                        </li>
                                        {props.isPublisher === true &&
                                            <TimerLi>
                                                <h4 className='mb-0 d-inline-block'>
                                                    <span className='badge'
                                                        style={{
                                                            paddingRight: '0.4rem',
                                                            paddingTop: '0.3rem',
                                                            paddingBottom: '0.35rem',
                                                            fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif',
                                                            backgroundColor: '#0000004d'

                                                        }}>
                                                        <Stopwatch />
                                                    </span>
                                                </h4>
                                            </TimerLi>
                                        }
                                    </HorizontalUl>
                                    {props.isPublisher === true &&
                                        <>
                                            {!isIPad13 &&
                                                <div>
                                                    <button className='btn shadow-none py-0 text-light' style={BtnStyle} onClick={() => setShowMirrorVideo(!showMirrorVideo)}><i className='fas fa-retweet' style={{ margin: '-2px' }}></i></button>
                                                </div>
                                            }
                                            <div className='mt-2'>
                                                <button className='btn shadow-none py-0 text-light' style={BtnStyle} onClick={onChangeOverlay}><i className={`fas fa-adjust ${isOverlayApply ? '' : 'fa-flip-horizontal'}`} style={{ margin: '-2px' }}></i></button>
                                            </div>
                                        </>
                                    }
                                    {props.isPublisher === true &&
                                        <div className='mt-2'>
                                            <button className='btn shadow-none py-0 text-light px-2' onClick={onShowUserList} style={BtnStyle}><i className='fas fa-eye' style={{ marginLeft: '2px' }}></i><span className='badge badge-danger' style={{
                                                top: '-18px',
                                                left: '-5px',
                                                fontFamily: 'Roboto'
                                            }}
                                            >{viewer}</span></button>
                                        </div>
                                    }

                                    {/* Switch video source for mobile devices */}
                                    {props.isPublisher === true
                                        && props.numberOfVideoDevices > 1
                                        && props.isMobileDevice &&
                                        <div className='mt-2'>
                                            <button
                                                className='btn shadow-none text-light'
                                                style={SwitchCameraBtnStyle}
                                                onClick={props.onSwitchCamera}
                                            >
                                                <Icons image={getCloudFrontAssetsUrl('images/camera-rotate.svg')} color={'white'}></Icons>
                                            </button>
                                        </div>
                                    }

                                    {/* Switch video and audio source for desktop devices */}
                                    {
                                        props.isPublisher === true &&
                                        !props.isMobileDevice &&
                                        <>
                                            {
                                                props.videoInputList.length > 1 &&
                                                <MediaSelection className='mt-3'>
                                                    <button
                                                        className='btn shadow-none text-light mr-2'
                                                        style={SwitchCameraBtnStyle}
                                                    >
                                                        <i className='fas fa-video'></i>
                                                    </button>
                                                    <select id='cam' name='cam' value={selectedVideoInputId} onChange={handleSelectChange}>
                                                        {props.videoInputList.map((option, index) => <option key={index} value={option.deviceId}>{`CAM ${index + 1} (${option.label})`}</option>)}
                                                    </select>
                                                </MediaSelection>
                                            }
                                            {
                                                <MediaSelection className='mt-3'>
                                                    {
                                                        props.isPublisherMuted === false ?
                                                            <button
                                                                className='btn shadow-none text-light mr-2'
                                                                style={SwitchCameraBtnStyle}
                                                                onClick={() => props.onMute()}
                                                            >
                                                                <i className='fas fa-microphone'></i>
                                                            </button>
                                                            : <button
                                                                className='btn shadow-none text-light mr-2'
                                                                style={SwitchCameraBtnStyle}
                                                                onClick={() => props.onUnmute()}
                                                            >
                                                                <i className='fas fa-microphone-slash text-danger'></i>
                                                            </button>
                                                    }
                                                    {
                                                        props.audioInputList.length > 1 &&
                                                        <select id='mic' name='mic' value={selectedAudioInputId} onChange={handleSelectChange}>
                                                            {props.audioInputList.map((option, index) => <option key={index} value={option.deviceId}>{`MIC ${index + 1} (${option.label})`}</option>)}
                                                        </select>
                                                    }
                                                </MediaSelection>
                                            }
                                        </>
                                    }

                                </div>
                                <div className='col-2 pl-2 px-0 text-right ml-auto d-inline-block pl-0' style={{ position: 'absolute', top: '4px', right: '0' }}>
                                    {props.auth.appSettings.enable_socket_debug === true &&
                                        <>
                                            {props.socket.isSocketConnected === true ?
                                                <div style={{ background: 'green', height: '10px', width: '10px', borderRadius: '50%', position: 'absolute', top: '17px', right: '55px' }}></div>
                                                :
                                                <div style={{ background: 'red', height: '10px', width: '10px', borderRadius: '50%', position: 'absolute', top: '17px', right: '55px' }}></div>
                                            }
                                        </>
                                    }
                                    <button className='btn shadow-none py-0 px-0 mx-0 text-light' style={BtnStyle} onClick={props.handleStopClick}><i className='fas fa-times'
                                        style={{ fontSize: '25px', padding: '0', lineHeight: '1.2', marginTop: '1px', marginLeft: '1px' }}></i></button>
                                </div>
                            </div>
                        </div>
                        <div className={`card-img-overlay p-0 justify-content-end ${props.isPublisher && isOverlayApply ? '' : ' bg-transparent'}`} style={{ backgroundColor: props.isPublisher && isOverlayApply ? `rgba(0,0,0,${overlayPercentage}%)` : '' }}>
                            <LiveStreamGroupMessages isPublisher={props.isPublisher} />
                        </div>
                        {props.auth.user.role !== 'live_stream_manager' && <div className='row comment-tip-section text-center' style={CommentSection}>
                            <div className='col-12'>
                                <ChatInputOnGroupLive startVideoOnAlert={startVideoOnAlert} />
                            </div>
                        </div>}
                        {showLiveUserList === true ?
                            <ModalPopUp title='Live Stream Viewers' handleClose={() => setShowLiveUserList(false)} modalBodyMaxHeight='50vh'
                            >
                                <>
                                    {viewerList.length > 0 && viewerList.map((user, index) => {
                                        return <div key={'user_list_' + index} className='row user-list-modal-row align-items-center border-bottom border-white justify-content-center mb-3 pb-3'>
                                            <div className='col-2 pl-2 px-0'>
                                                <Image src={user.avatarUrl ? user.avatarUrl : 'images/avatar.png'} alt=''></Image>
                                            </div>
                                            <div className='col-9 text-left'>
                                                {user.name}
                                            </div>
                                            <div className='col-1 text-right'>
                                                {user.count > 1 &&
                                                    <span className='badge bg-info rounded-pill pt-1'>
                                                        {user.count}
                                                    </span>
                                                }
                                            </div>
                                        </div>
                                    })}
                                </>
                            </ModalPopUp> : <></>
                        }
                    </VideoBanner>
                </LiveStreamPageMainDiv>
                :
                <WebsiteFontColor>
                    <div className='modal fade show' role='dialog' style={{ display: 'block', backgroundColor: '#00000080' }}>
                        <div className='modal-dialog modal-dialog-centered' role='document'>
                            <div className='modal-content'>
                                <div className='modal-body' style={{ backgroundColor: card_background_color }}>
                                    <div className='container'>
                                        <div className='row'>
                                            <div className='col-12 text-center'>
                                                <div>Go Live Stream is connecting...</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </WebsiteFontColor>
            }
        </>
    )
}

GroupLiveStreamModal.propTypes = {
    isPublisher: PropTypes.bool.isRequired,
    handleStopClick: PropTypes.func.isRequired,
    video: PropTypes.object.isRequired,
    chat: PropTypes.object.isRequired,
    auth: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    isPublisherMuted: PropTypes.bool,
    onMute: PropTypes.func,
    onUnmute: PropTypes.func,
    loading: PropTypes.bool,
    socket: PropTypes.object.isRequired,
    onSwitchCamera: PropTypes.func,
    numberOfVideoDevices: PropTypes.number,
    audioInputList: PropTypes.array,
    videoInputList: PropTypes.array,
    isMobileDevice: PropTypes.bool
}

const mapStateToProps = state => ({
    chat: state.chat,
    auth: state.auth,
    socket: state.socket
})

export default connect(mapStateToProps)(withRouter(GroupLiveStreamModal))

import axios from 'axios'
import _ from 'lodash'
import { getAppBaseUrl } from './api'
import { getAppSettings } from './appSettingsActions'
import { paymentProcessing, paymentResponseRecieved } from './ccbillActions'
import {
    REMOVE_GROUP_LIVE_STREAM,
    SET_GROUP_LIVE_STREAM,
    ADD_GROUP_MESSAGE,
    SET_LIVE_STREAM_LOADER,
    SET_START_STREAM,
    SET_IS_LIVE,
    SET_STREAM_JOINED,
    SET_STREAM_TOKEN,
    SET_CAN_MODEL_GO_LIVE,
    SET_SERVER_TAB_TOKEN,
    SET_ROOM,
    SET_PUB_ID,
    SET_PUB_PVT_ID,
    SET_SEND_GO_LIVE_STREAM_MESSAGE,
    SET_LIVE_STREAM_STATUS,
    START_LIVE_STREAM
} from './types'
import { setSweetAlert } from './sweetAlertActions'

const BASE_URL = getAppBaseUrl()

export const addToken = (data, callback) => () => {
    axios.post(BASE_URL + '/api/stream/add_token', data)
        .then(res => {
            callback(res.data)
        })
}

export const removeToken = () => () => {
    axios.post(BASE_URL + '/api/stream/remove_token')
        .then(res => {
            console.log({ res })
        })
}

export const getStreamSettings = (callback) => () => {
    axios.get(BASE_URL + '/api/stream/get_settings')
        .then((res) => {
            callback(res.data)
        }).catch((err) => {
            alert(err)
            callback()
        })
}

export const saveStreamSettings = (data, callback) => dispatch => {
    axios.post(BASE_URL + '/api/stream/save_settings', data)
        .then(() => {
            alert('update settings successfully.')
            dispatch(getAppSettings(() => { }))
            callback()
        }).catch((err) => {
            console.log(err)
            alert('Error in update settings.')
            callback()
        })
}

export const startStream = (data, callback) => () => {
    axios.post(BASE_URL + '/api/stream/start_stream', data)
        .then((res) => {
            callback(res.data)
        })
        .catch((err) => {
            console.log(err)
        })
}

export const stopStream = (data) => dispatch => {
    axios.post(BASE_URL + '/api/stream/stop_stream', data)
        .then(() => {
            dispatch(removeGroupStreamData())
        })
        .catch((err) => {
            console.log(err)
        })
}

export const setStreamData = (data, callback) => () => {
    axios.post(BASE_URL + '/api/stream/set_stream_data', data)
        .then(() => {
            callback(true)
        })
        .catch((err) => {
            console.log(err)
        })
}

export const getLiveStreamReports = (data, callback) => () => {
    axios.post(BASE_URL + '/api/stream/get_live_stream_reports', data)
        .then((res) => {
            callback(res.data.data)
        })
        .catch((err) => {
            const error = {
                isError: true,
                message: err.response.data.message
            }
            callback(error)
        })
}

export const sendGroupStreamMessage = (data, callback) => (dispatch) => {
    axios.post(BASE_URL + '/api/stream/send_message', data)
        .then((res) => {
            callback(res)
        }).catch(error => {
            error.message = _.get(error, 'response.data.message', error.message)
            if (error.response.status === 401) {
                return callback(error.response)
            }
            callback(error.response)
            dispatch(setSweetAlert({ description: `Could not send Message: ${error.message}` }))
        })
}

export const sendTipsByAdmin = (data, callback) => dispatch => {
    dispatch(paymentProcessing())
    axios.post(BASE_URL + '/api/stream/send_tips', data)
        .then(() => {
            callback()
            dispatch(paymentResponseRecieved())
        }).catch(error => {
            error.message = _.get(error, 'response.data.message', error.message)
            dispatch(setSweetAlert({ description: `Could not send Message: ${error.message}` }))
        })
}

export const setGroupStreamData = () => {
    return {
        type: SET_GROUP_LIVE_STREAM
    }
}

export const removeGroupStreamData = () => {
    return {
        type: REMOVE_GROUP_LIVE_STREAM
    }
}

export const addGroupMessageInEnd = (data) => {
    return {
        type: ADD_GROUP_MESSAGE,
        payload: data
    }
}

export const setStreamLoader = (data) => {
    return {
        type: SET_LIVE_STREAM_LOADER,
        payload: data
    }
}

export const setStartStream = () => {
    return {
        type: SET_START_STREAM
    }
}

export const setIsLive = () => {
    return {
        type: SET_IS_LIVE
    }
}

export const setStreamJoined = () => {
    return {
        type: SET_STREAM_JOINED
    }
}

export const setStreamToken = (data) => {
    return {
        type: SET_STREAM_TOKEN,
        payload: data
    }
}

export const setCanModelGoLive = (data) => {
    return {
        type: SET_CAN_MODEL_GO_LIVE,
        payload: data
    }
}

export const setServerTabToken = (data) => {
    return {
        type: SET_SERVER_TAB_TOKEN,
        payload: data
    }
}

export const setRoom = (data) => {
    return {
        type: SET_ROOM,
        payload: data
    }
}

export const setPubId = (data) => {
    return {
        type: SET_PUB_ID,
        payload: data
    }
}

export const setPubPvtId = (data) => {
    return {
        type: SET_PUB_PVT_ID,
        payload: data
    }
}

export const setSendGoLiveStreamMessage = (data) => {
    return {
        type: SET_SEND_GO_LIVE_STREAM_MESSAGE,
        payload: data
    }
}

export const setLiveStreamStatus = (data) => {
    return {
        type: SET_LIVE_STREAM_STATUS,
        payload: data
    }
}

export const startLiveStream = (data) => {
    return {
        type: START_LIVE_STREAM,
        payload: data
    }
}

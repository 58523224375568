import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import Loader from '../Layouts/Loader'
import styled from 'styled-components'
import classNames from 'classnames'
import moment from 'moment'
import _ from 'lodash'
import ChatHeader from './ChatHeader'
import ChatInputSection from './ChatInputSection'
import ChatMedia from './ChatMedia'
import socket from '../../utils/socket'
import {
    readMessage,
    addNewMessageInEnd,
    updateMessageAction,
    getMessages,
    updateIsMessagesLoading,
    sendTextMessage,
    setIsMoreMessagesLoading,
    loadMoreMessages
} from '../../actions/chatActions'
import Linkify from 'react-linkify'
import punycode from 'punycode/'
import MessageSettings from './MessageSettings'
import { openCopyToClipboardToast } from '../../actions/copyToClipboardAction'

const MainDiv = styled.div`
    height: ${props => props.isLoading === 'true' ? '100%' : 'auto'};
    .pointer-event-none {
        pointer-events: none;
    }
`

const StyledDiv = styled.div`
    margin: 0 30px;
    display: flex;
    flex-direction: column;

    .message {
        padding: 10px;
        border-radius: 10px;
        margin-bottom: 10px;
        max-width: 90%;
    }

    .receiver-message {
        background-color: ${props => props.chatReceiverMessageBackgroundColor};
        color: ${props => props.chatReceiverMessageFontColor};
        align-self: flex-start;
        padding: 0;
        box-shadow: 0px 0px 1px 1px;
        position: relative;

        a {
            color: ${props => props.chatReceiverMessageFontColor} !important;
            text-decoration: underline !important;
            cursor: pointer;
        }
    }

    .sender-message {
        background-color: ${props => props.chatSenderMessageBackgroundColor};
        color: ${props => props.chatSenderMessageFontColor};
        align-self: flex-end;
        position: relative;

        a {
            color: ${props => props.chatSenderMessageFontColor} !important;
            text-decoration: underline !important;
            cursor: pointer;
        }
    }

    .tips {
        background-color: #9cff88;
        color: #000000;
        border: none;
    }

    .price {
        font-size: 12px;
        margin-right: 10px;
    }

    .message.receiver-message.tips:after {
        content: '';
        display: block;
        left: -5px;
        top: 0;
        background-color: #9cff88;
        position: absolute;
        width: 20px;
        height: 10px;
        transform: skew(35deg, 8deg) rotate(-8deg);
        border-bottom: 0px;
        border-right: 0px;
        box-shadow: 0px 0px 1px 1px;
    }

    .message.sender-message:after {
        content: '';
        display: block;
        right: -3px;
        top: 0;
        background-color: ${props => props.chatSenderMessageBackgroundColor};
        position: absolute;
        width: 20px;
        height: 10px;
        transform: skew(-49deg, 7deg) rotate(-8deg);
        border-bottom: 0px;
        border-right: 0px;
    }

    .message.receiver-message:after {
        content: '';
        display: block;
        left: -5px;
        top: 0;
        background-color: ${props => props.chatReceiverMessageBackgroundColor};
        position: absolute;
        width: 20px;
        height: 10px;
        transform: skew(35deg, 8deg) rotate(-8deg);
        border-bottom: 0px;
        border-right: 0px;
        box-shadow: 0px 0px 1px 1px ${props => props.chatReceiverMessageFontColor};
    }

    .main {
        padding: 10px;
        z-index: ${props => props.zIndex};
        position: relative;
        background: ${props => props.chatReceiverMessageBackgroundColor};
        border-radius: 10px;
    }

    .message.receiver-message.tips .main {
        background: #9cff88;
    }

    .msg {
        word-wrap: break-word;
        white-space: pre-wrap;
    }

    .fa-ellipsis-v {
        color: ${props => props.messageEllipsisColor};
        cursor: pointer;
        right: -20px;
        padding: 10px;
        bottom: 8px;
    }

    &.chat-messages {
        min-height: 750px;
        justify-content: end;
    }

    @media(max-width: 576px){
        padding-bottom: 43px;
    }
`

const TimeSpan = styled.span`
    font-size: 10px;
`

const PriceText = styled.span`
    text-decoration: line-through;
    text-decoration-color: ${props => props.textDecorationColor}; 
    textDecorationThickness: 2px;
`

function Messages(props) {
    const { chat, auth, colorScheme, promotion } = props
    const {
        chatSenderMessageBackgroundColor,
        chatSenderMessageFontColor,
        chatSenderButtonBackgroundColor,
        chatSenderButtonFontColor,
        chatReceiverMessageBackgroundColor,
        chatReceiverMessageFontColor,
        messageEllipsisColor } = colorScheme

    const [canChat, setCanChat] = useState(false)
    const [chatStartTime, setChatStartTime] = useState(null)
    const [chatEndTime, setChatEndTime] = useState(null)
    const [chatEndTimeWithDelay, setChatEndTimeWithDelay] = useState(null)
    const [countDownDuration, setCountDownDuration] = useState(0)
    const { isAdmin, role, _id } = auth.user
    const { content_color, content_font_color, enable_promotion, promotion_settings } = auth.appSettings
    const { isMessagesLoading, messages, selectedUserId, userProfileInfo, currentMessagesPage, shouldLoadMoreMessages, isMoreMessageLoading } = chat
    const selectedUserProfileDetails = userProfileInfo[selectedUserId]
    const [tempLoading, setTempLoading] = useState(true)
    const [isPopupOpen, setIsPopupOpen] = useState(false)
    const [activeSettingForMessageIndex, setActiveSettingForMessageIndex] = useState(-1)
    const { lockedContentPromotion } = promotion
    const isMassMessagePromotionActive = enable_promotion &&
        lockedContentPromotion &&
        lockedContentPromotion.type === 'LOCKED_CONTENT' &&
        ['EXCLUSIVE_CONTENT_AND_MASS_MESSAGE', 'MASS_MESSAGE'].includes(lockedContentPromotion.applicable_to) ? true : false
    let messageScrollPosition = 0

    const loadOldMessages = async () => {
        recordScrollPosition()
        const modelId = props.auth.appSettings.model_id
        let data = {
            userId: chat.selectedUserId,
            modelId: modelId,
            pageNum: currentMessagesPage + 1
        }
        await props.loadMoreMessages(data, auth.user.isAdmin)
        restoreScrollPosition()
    }

    const handleScroll = (e) => {
        e.preventDefault()
        if (!shouldLoadMoreMessages) {
            e.target.removeEventListener('scroll', handleScroll)
            return
        }

        let winScroll = e.target.scrollTop
        let height = e.target.scrollHeight - e.target.clientHeight
        const scrolled = winScroll / height
        if (scrolled === 0) {
            e.target.removeEventListener('scroll', handleScroll)
            loadOldMessages()
        }
    }

    useEffect(() => {
        if (currentMessagesPage >= 1) {
            const msgContainer = document.getElementById('message-list')
            msgContainer.addEventListener('scroll', handleScroll)
        }
        // TODO: add event lister if current-message-page is 1
    }, [currentMessagesPage, selectedUserId, shouldLoadMoreMessages])

    const recordScrollPosition = () => {
        const objDiv = document.getElementById('message-list')
        messageScrollPosition = objDiv.scrollHeight + 10
    }

    const restoreScrollPosition = () => {
        const objDiv = document.getElementById('message-list')
        const currentScrollPosition = objDiv.scrollHeight - messageScrollPosition
        objDiv.scrollTop = currentScrollPosition
    }

    const receiveMessage = () => {
        socket.on('MESSAGE_RECEIVE', (msg) => {
            const userIdString = _id.toString()
            const { selectedUserId, selectedModelId } = props.chat
            const { receiverId, senderId } = msg
            const receiverIdString = receiverId.toString()
            const senderIdString = senderId.toString()
            let shouldShowMessage = false

            // Show messages to all the admins if the message is to/from the same user as the open chat
            if (isAdmin && [receiverIdString, senderIdString].includes(selectedUserId)) {
                shouldShowMessage = true
            }

            // Show messages to users if the message is sent/received by them
            if (isAdmin === false && [receiverIdString, senderIdString].includes(userIdString)) {
                shouldShowMessage = true
            }

            if (shouldShowMessage) {
                let data = {
                    userId: selectedUserId
                }
                if ((isAdmin)) {
                    data.modelId = props.auth.appSettings.model_id ? props.auth.appSettings.model_id : selectedModelId
                }
                addMessage(msg)
                if (role !== 'admin') {
                    props.readMessage(data, isAdmin)
                }
            }
        })
    }

    const addMessage = (message) => {
        props.addNewMessageInEnd(message)
        if (message && !message.isRotated) {
            scrollToBottom()
        }
    }

    useEffect(() => {
        const setupSocketConnection = () => {
            if (socket.disconnected) {
                socket.connect()
            }

            const roomData = {
                roomId: selectedUserId,
                userId: props.auth.user._id
            }
            socket.emit('JOIN_ROOM', roomData)

            if (isAdmin) {
                socket.emit('GET_ONLINE_USER_LIST')
                socket.emit('ADMIN_IN_USER_CHAT', { adminId: props.auth.user._id, userId: selectedUserId })
            } else if (typeof props.auth.user._id !== 'undefined' && props.auth.user.isAdmin === false && props.auth.user.role !== 'proxy_user') {
                socket.emit('USER_ONLINE', props.auth.user._id)
            }

            receiveMessage()
        }

        setupSocketConnection()

        return () => {
            socket.off('MESSAGE_RECEIVE')
        }
    }, [selectedUserId])

    useEffect(() => {
        if (props.match.params.id !== undefined && isMessagesLoading === false) {
            props.updateIsMessagesLoading(true)
            const userId = props.match.params.id
            const modelId = props.auth.appSettings.model_id

            let data = {
                userId: props.auth.user._id,
                selectedUserId: userId,
                selectedModelId: modelId,
                pageNum: 1,
                role: props.auth.user.role
            }
            props.getMessages(data, props.auth.user.isAdmin)
        }
        updateMessage()

        // Improves UX, when user come back again on chat page
        setTimeout(() => {
            setTempLoading(false)
            scrollToBottom()
        }, 500)
    }, [])

    // Scroll to bottom whenever messages change
    const scrollToBottom = () => {
        const objDiv = document.getElementById('message-list')
        if (objDiv) {
            objDiv.scrollTop = objDiv.scrollHeight
        }
    }

    const handleTextMessageSend = (data, callback) => {
        props.sendTextMessage(data, (status) => {
            if (status === false) {
                props.history.push('profile/add-new-payment-method')
            } else {
                callback()
            }
        })
    }

    const getDiscountPrice = (amount) => {
        let promotionPercentage = 0
        if (isMassMessagePromotionActive) promotionPercentage = lockedContentPromotion.discount_percentage

        if (promotionPercentage === 0) {
            return amount
        }
        return Math.ceil(amount * (100 - promotionPercentage) / 100)
    }

    const updateMessage = () => {
        socket.on('UPDATE_MESSAGE', (msg) => {
            const messageId = _.get(msg, 'messageId', false)
            if (messageId !== false) {
                msg._id = messageId
            }
            props.updateMessageAction(msg)
        })
    }

    const componentDecorator = (href, text, key) => (
        <a href={href} key={key} target='_blank' rel='noopener noreferrer'>
            {punycode.toASCII(text)}
        </a>
    )

    const copyToClipboard = (value) => {
        if (auth.user.isAdmin === true) {
            navigator.clipboard.writeText(value)
            props.openCopyToClipboardToast()
        }
    }

    return <MainDiv isLoading={(isMessagesLoading || tempLoading) ? 'true' : 'false'}>
        {_.isEmpty(selectedUserId) === false &&
            <ChatHeader
                colorScheme={colorScheme}
                handleTextMessageSend={handleTextMessageSend}
                scrollToBottom={scrollToBottom}
                canChat={canChat}
                setCanChat={setCanChat}
                chatStartTime={chatStartTime}
                setChatStartTime={setChatStartTime}
                chatEndTime={chatEndTime}
                setChatEndTime={setChatEndTime}
                chatEndTimeWithDelay={chatEndTimeWithDelay}
                setChatEndTimeWithDelay={setChatEndTimeWithDelay}
                countDownDuration={countDownDuration}
                setCountDownDuration={setCountDownDuration}
            />
        }
        {isMessagesLoading || tempLoading
            ?
            <div className='text-center d-flex align-itens-center justify-content-center h-100'>
                <Loader style={{ position: 'fixed', top: '50%' }} loading={isMessagesLoading || tempLoading} size={10} />
            </div>
            :
            <StyledDiv
                className='chat-messages mt-2'
                contentColor={content_color}
                contentFontColor={content_font_color}
                chatSenderMessageBackgroundColor={chatSenderMessageBackgroundColor}
                chatSenderMessageFontColor={chatSenderMessageFontColor}
                chatReceiverMessageBackgroundColor={chatReceiverMessageBackgroundColor}
                chatReceiverMessageFontColor={chatReceiverMessageFontColor}
                messageEllipsisColor={messageEllipsisColor}
                zIndex={isPopupOpen ? '' : '1'}
            >
                {isMoreMessageLoading &&
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Loader loading={true} size={10} />
                    </div>
                }

                {messages.length > 0 && messages.map((message, i) => {
                    const isSenderAdmin = isAdmin && message.fromAdmin
                    const isTipsMessage = message.type === 'tips'
                    let chatMessage = message.message
                    let tipMessage = message.tipMessage

                    if (isAdmin === true && message.fromAdmin === false && message.processing === true) {
                        return <></>
                    }
                    if (isTipsMessage) {
                        chatMessage = `${selectedUserProfileDetails ? selectedUserProfileDetails.name : 'user'} just tipped you ${message.message}!`
                    }

                    if (message.type === 'GO_LIVE_STREAM') {
                        chatMessage = `Go Live Stream with ${selectedUserProfileDetails ? selectedUserProfileDetails.name : 'user'} has ended.`
                    }

                    if (message.type !== 'system') {
                        return (
                            <div
                                id={message._id}
                                key={i}
                                className={
                                    classNames('message', {
                                        'receiver-message': !isSenderAdmin,
                                        'sender-message': isSenderAdmin,
                                        'tips': isTipsMessage
                                    })
                                }
                            >
                                <div
                                    className={
                                        classNames('', {
                                            'main': !isSenderAdmin
                                        })
                                    }
                                >
                                    {['photo', 'video', 'gallery'].includes(message.type) &&
                                        <ChatMedia
                                            buttonBackgroundColor={isSenderAdmin ? chatSenderButtonBackgroundColor : ''}
                                            buttonFontColor={isSenderAdmin ? chatSenderButtonFontColor : ''}
                                            message={message}
                                            setIsPopupOpen={setIsPopupOpen}
                                            index={i} />
                                    }
                                    {!_.isEmpty(chatMessage) && <>
                                        <Linkify componentDecorator={componentDecorator}>
                                            <span onClick={() => copyToClipboard(chatMessage)} className='msg'>{chatMessage}</span>
                                            {isTipsMessage && !_.isEmpty(tipMessage) && <>
                                                <br />
                                                <span onClick={() => copyToClipboard(tipMessage)} className='msg'>{tipMessage}</span>
                                            </>}
                                        </Linkify>
                                        <br />
                                    </>
                                    }
                                    <div className='d-flex justify-content-between align-items-baseline'>
                                        {(message.isLocked === 'locked' || message.isLocked === 'unlocked') && isAdmin &&
                                            role !== 'live_stream_manager' && !message.processing &&
                                            <span className='price'>
                                                <i className={
                                                    classNames('fas mr-2', {
                                                        'fa-lock': message.isLocked === 'locked',
                                                        'fa-unlock': message.isLocked === 'unlocked'
                                                    })
                                                }></i>
                                                {message.promotion_price ?
                                                    <>
                                                        <PriceText textDecorationColor={promotion_settings.price_strike_through_color}>
                                                            ${message.amount}
                                                        </PriceText> ${message.promotion_price}
                                                    </> :
                                                    <>
                                                        {isMassMessagePromotionActive === true && message.isMassMessage === true && message.isLocked === 'locked' ?
                                                            <>
                                                                <PriceText textDecorationColor={promotion_settings.price_strike_through_color}>
                                                                    ${message.amount}
                                                                </PriceText> ${getDiscountPrice(message.amount)}
                                                            </>
                                                            :
                                                            <>${message.amount} </>
                                                        }
                                                    </>
                                                }
                                            </span>
                                        }
                                        <TimeSpan
                                            className={
                                                classNames('text-muted ', {
                                                    'ml-auto': isSenderAdmin
                                                })
                                            }
                                        >
                                            {moment(message.created).calendar()}
                                        </TimeSpan>
                                    </div>
                                </div>
                                <MessageSettings
                                    message={message}
                                    indexInfo={[message._id, activeSettingForMessageIndex]}
                                    onClick={(i) => setActiveSettingForMessageIndex(i)}
                                />
                            </div>
                        )
                    }
                })}
            </StyledDiv>
        }
        <span className={
            classNames({
                'pointer-event-none': isMessagesLoading || tempLoading
            })
        }>
            <ChatInputSection colorScheme={colorScheme} />
        </span>
    </MainDiv>
}

Messages.propTypes = {
    auth: PropTypes.object.isRequired,
    chat: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    promotion: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    getMessages: PropTypes.func.isRequired,
    setIsMoreMessagesLoading: PropTypes.func.isRequired,
    loadMoreMessages: PropTypes.func.isRequired,
    colorScheme: PropTypes.object.isRequired,
    updateIsMessagesLoading: PropTypes.func.isRequired,
    readMessage: PropTypes.func.isRequired,
    addNewMessageInEnd: PropTypes.func.isRequired,
    updateMessageAction: PropTypes.func.isRequired,
    sendTextMessage: PropTypes.func.isRequired,
    openCopyToClipboardToast: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
    auth: state.auth,
    chat: state.chat,
    promotion: state.promotion
})

export default connect(
    mapStateToProps,
    {
        getMessages,
        setIsMoreMessagesLoading,
        loadMoreMessages,
        readMessage,
        addNewMessageInEnd,
        updateMessageAction,
        updateIsMessagesLoading,
        sendTextMessage,
        openCopyToClipboardToast
    }
)(withRouter(Messages))

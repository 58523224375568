import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import _ from 'lodash'
import styled from 'styled-components'
import { mediaTypes } from '../../utils/constant'
import Button from '../Layouts/Button'
import BlogGalleryDialog from './../Layouts/BlogGalleryDialog'
import FullScreenModelPopUpDialog from '../Layouts/FullScreenModelPopUpDialog'
import { getCloudFrontAssetsUrl } from '../../utils/assets'
import { withRouter } from 'react-router-dom'

const ImageDiv = styled.div`
    position: relative;
    min-width: 150px;

    .image-button-container {
        width: fit-content;
        margin: auto;
    }

    .img-div {
        height: 150px;
        position: relative;
        text-align: center;
        cursor: pointer;
        min-width: auto;
    }

    .preview-btn {
        margin: 0px;
        width: max-content;
        min-width: 100%;
    }
`

const GalleryMediaCounts = styled.div`
    font-size: 16px;
    color: #000000;
    text-align: center;
    position: absolute;
    left: 50%;
    bottom: 5px;
    transform: translateX(-50%);
    width: max-content;

    span { 
        background-color: #ddddddaa;
        border-radius: 5px;
        padding: 0 5px;
        display: inline-block;
    }
`

const Image = styled.img`
    width: auto;
    height: 150px;
    object-fit: contain;
    border-radius: 4px;
`

function ChatMedia(props) {
    const {
        content_color,
        content_font_color,
        blur_user_sent_media,
        button_shadow_color
    } = props.auth.appSettings
    const { classes } = props
    const [urls, setUrl] = useState('')
    const [type, setType] = useState('')
    const [isDialogOpen, setIsDialogOpen] = useState(false)
    const [isPreviewOpen, setIsPreviewOpen] = useState(false)
    const [index, setIndex] = useState(-1)
    // const [pageNum, setPageNum] = useState(1)

    let messageType = props.message.type
    if ((mediaTypes.includes(messageType)) && props.message.media.length > 0) {
        messageType = 'gallery'
        if (props.message.media.length === 1) {
            messageType = props.message.media[0].content_type
        }
    }

    let imageUrl = ''
    if (messageType === 'photo') {
        if (blur_user_sent_media === true && props.auth.user.isAdmin === true && props.message.media.length > 0 && !_.isEmpty(props.message.media[0].blur_url)) {
            imageUrl = props.message.media[0].blur_url
        } else if (props.message.media.length > 0 && !_.isEmpty(props.message.media[0].thumbnail_url)) {
            imageUrl = props.message.media[0].thumbnail_url
        } else {
            imageUrl = props.message.mediaUrl
        }
    }

    let videoUrl = ''
    if (messageType === 'video') {
        if (blur_user_sent_media === true && props.auth.user.isAdmin === true && props.auth.media.length > 0 && !_.isEmpty(props.message.media[0].blur_url)) {
            videoUrl = props.message.media[0].blur_url
        } else {
            videoUrl = _.isEmpty(props.message.media) === false ? props.message.media[0].thumbnail_url : ''
        }
    }
    let contentUrlForMyMessage = ''
    if (messageType === 'photo') {
        contentUrlForMyMessage = imageUrl
    } else if (messageType === 'video') {
        contentUrlForMyMessage = videoUrl
    } else if (messageType === 'gallery') {
        contentUrlForMyMessage = _.isEmpty(props.message.media) === false ? props.message.media[0].thumbnail_url : ''
    }

    function getGalleryPhotoAndVideoCount() {
        const photo = _.get(props.message.contentCount, 'photo', 0)
        const video = _.get(props.message.contentCount, 'video', 0)
        if (messageType === 'video') {
            return <GalleryMediaCounts contentColor={content_color} fontColor={content_font_color}>
                <span>
                    <i className='fas fa-video' /> 1
                </span>
            </GalleryMediaCounts>
        }
        if (messageType !== 'gallery') {
            return <></>
        }

        return <>
            {photo > 0 && video > 0 &&
                <GalleryMediaCounts contentColor={content_color} fontColor={content_font_color}>
                    <span>
                        <i className='fas fa-image' /> {photo} | <i className='fas fa-video' /> {video}
                    </span>
                </GalleryMediaCounts>
            }
            {
                photo > 0 && video === 0 &&
                <GalleryMediaCounts contentColor={content_color} fontColor={content_font_color}>
                    <span><i className='fas fa-image' /> {photo}</span>
                </GalleryMediaCounts>
            }
            {
                photo === 0 && video > 0 &&
                <GalleryMediaCounts contentColor={content_color} fontColor={content_font_color}>
                    <span><i className='fas fa-video' /> {video}</span>
                </GalleryMediaCounts>
            }
        </>
    }
    const openFullScreenDlg = (media) => {
        setUrl(media.url)
        setType(media.content_type)
        setIsPreviewOpen(!isPreviewOpen)
    }
    const openDlgBlog = (index) => {
        setIsDialogOpen(true)
        setIndex(index)
        if (props.setIsPopupOpen) props.setIsPopupOpen(true)
    }

    const closeDialogBlog = () => {
        setIsDialogOpen(false)
        if (props.setIsPopupOpen) props.setIsPopupOpen(false)
    }
    const processImageFilePath = getCloudFrontAssetsUrl('blog/process_image.png')
    return <div className='mb-2'>
        {
            props.message.processing &&
            <div style={{ display: 'inline-block' }}>
                <div
                    className='img-div'
                    style={{
                        backgroundImage: `url(${processImageFilePath})`,
                        width: 'auto',
                        height: '150px'
                    }}
                />
                <div>
                    This message is under process. It will be visible to {props.auth.isAdmin ? 'you' : 'users'} when processing is completed.
                </div>
            </div>


        }
        {contentUrlForMyMessage !== '' && !props.message.processing ?
            <ImageDiv
                className='text-center'
                button_shadow_color={button_shadow_color}
                content_font_color={content_font_color}
            >
                <div
                    className='image-button-container'
                >
                    <div
                        className='img-div mb-2'
                        onClick={() => openDlgBlog(props.index)}
                    >
                        <Image
                            src={contentUrlForMyMessage}
                            alt=''
                        />
                        {getGalleryPhotoAndVideoCount()}
                    </div>
                    {(mediaTypes.includes(props.message.type)) && props.message.media_preview.length > 0 &&
                        <div className='text-center'>
                            <Button
                                backgroundColor={props.buttonBackgroundColor}
                                fontColor={props.buttonFontColor}
                                loading={false}
                                type='button'
                                onClick={() => openFullScreenDlg(props.message.media_preview[0])}
                                classes='btn-sm preview-btn'
                            >
                                Preview
                            </Button>
                        </div>
                    }
                </div>
            </ImageDiv>
            :
            <></>
        }
        {isDialogOpen && index >= 0 && props.message.type === 'gallery' &&
            <BlogGalleryDialog
                galleryImages={props.message.galleryMediaUrl}
                closeDialogBlog={closeDialogBlog}
                classes={classes}
                userId={props.auth.user._id}
                galleryIndex={0}
                contentFrom='massMessage'
                media={props.message.media}
            />
        }
        <>
            {isDialogOpen && props.message.type !== 'gallery' &&
                <FullScreenModelPopUpDialog
                    url={props.message.media[0].url}
                    handleClose={closeDialogBlog}
                    type={props.message.type}
                />
            }
        </>
        {isPreviewOpen &&
            <FullScreenModelPopUpDialog
                url={urls}
                handleClose={() => { openFullScreenDlg('') }}
                type={type}
                showWatermark={props.auth.user.isAdmin ? false : true}
            />
        }
    </div>
}

const mapStateToProps = state => ({
    auth: state.auth,
    chat: state.chat,
    media: state.media
})

ChatMedia.propTypes = {
    classes: PropTypes.object,
    index: PropTypes.number,
    auth: PropTypes.object.isRequired,
    chat: PropTypes.object.isRequired,
    media: PropTypes.object.isRequired,
    message: PropTypes.object.isRequired,
    buttonBackgroundColor: PropTypes.string,
    buttonFontColor: PropTypes.string,
    setIsPopupOpen: PropTypes.func
}

export default connect(
    mapStateToProps
)(withRouter(ChatMedia))

import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import PageWrapper from '../Layouts/PageWrapper'
import WebsiteFontColor from '../Layouts/WebsiteFontColor'
import ChatUserList from './ChatUserList'
import {
    getUserList,
    setSelectedChatModelId,
    setIsChatScreenOpen,
    setSelectedUserId
} from './../../actions/chatActions'
import styled from 'styled-components'
import Messages from './Messages'
import _ from 'lodash'
import classNames from 'classnames'

const ChatContainerDiv = styled.div`
    background:  ${props => props.backgroundColor};
    max-width: 100% !important;
`

const ChatListDiv = styled.div`
    border-right: 1px solid ${props => props.borderColor};
    background-color: ${props => props.backgroundColor};
    padding: 0 !important;
    margin: 0 !important;
    height: calc(100dvh - 44px - env(safe-area-inset-bottom, 0));
    overflow: scroll;
    overflow-x: hidden;

    @media(max-width: 992px){
        height: calc(100dvh - 100px - env(safe-area-inset-bottom, 0));
        border: none;
    }

    @media(max-width: 768px){
        height: calc(100dvh - 101px - env(safe-area-inset-bottom, 0));
    }

    ::-webkit-scrollbar {
        display: none;
    }
`

const MessageDiv = styled.div`
    padding: 0 !important;
    margin: 0 !important;
    overflow: scroll;
    overflow-x: hidden;
    height: calc(100dvh - 44px - env(safe-area-inset-bottom, 0));
    background: ${props => props.chatBackgroundColor};
    ::-webkit-scrollbar {
        display: none;
    }
    @media(max-width: 992px){
        height: calc(100dvh - env(safe-area-inset-bottom, 0));
        max-height: 100dvh !important;
        position: absolute !important;
    }
`

const getColorScheme = (settings) => {
    const {
        chat_header_background_color,
        chat_header_font_color,
        chat_input_section_background_color,
        chat_sender_message_background_color,
        chat_sender_message_font_color,
        chat_receiver_message_background_color,
        chat_receiver_message_font_color,
        chat_background_color,
        chat_user_list_background_color,
        chat_user_list_font_color,
        chat_selected_user_background_color,
        chat_selected_user_font_color,
        chat_filter_button_background_color,
        chat_filter_button_font_color,
        chat_button_background_color,
        chat_button_font_color,
        chat_border_color,
        card_background_color,
        content_color,
        content_font_color,
        site_background_color,
        site_font_color,
        card_header_border_color
    } = settings
    return {
        chatHeaderBackgroundColor: chat_header_background_color || card_background_color,
        chatHeaderFontColor: chat_header_font_color || site_font_color,
        chatInputSectionBackgroundColor: chat_input_section_background_color || card_background_color,
        chatSenderMessageBackgroundColor: chat_sender_message_background_color || content_color,
        chatSenderMessageFontColor: chat_sender_message_font_color || content_font_color,
        chatSenderButtonBackgroundColor: chat_sender_message_font_color || content_font_color,
        chatSenderButtonFontColor: chat_sender_message_background_color || content_color,
        chatReceiverMessageBackgroundColor: chat_receiver_message_background_color || content_font_color,
        chatReceiverMessageFontColor: chat_receiver_message_font_color || content_color,
        chatBackgroundColor: chat_background_color || site_background_color,
        chatFilterBackgroundColor: chat_user_list_background_color || card_background_color,
        chatUserListBackgroundColor: chat_user_list_background_color || card_background_color,
        chatUserListFontColor: chat_user_list_font_color || site_font_color,
        chatSelectedUserBackgroundColor: chat_selected_user_background_color || content_color,
        chatSelectedUserFontColor: chat_selected_user_font_color || content_font_color,
        chatFilterButtonBackgroundColor: chat_filter_button_background_color || content_color,
        chatFilterButtonFontColor: chat_filter_button_font_color || content_font_color,
        chatButtonBackgroundColor: chat_button_background_color || content_color,
        chatButtonFontColor: chat_button_font_color || content_font_color,
        chatBorderColor: chat_border_color || card_header_border_color,
        messageEllipsisColor: chat_sender_message_font_color || content_color
    }
}

function Chat(props) {
    const { chat, auth } = props
    const colorScheme = getColorScheme(auth.appSettings)
    const { chatUserListBackgroundColor, chatBackgroundColor, chatBorderColor } = colorScheme
    const {
        selectedUserId,
        isChatScreenOpen,
        userList,
        searchedUsername
    } = chat

    const [page, setPage] = useState(1)
    const [loadMoreUser, setLoadMoreUser] = useState(false)

    const updateChatInputHeight = () => {
        const nav = document.querySelector('.navbar')
        const chatList = document.getElementById('message-list')
        if (chatList && nav) {
            const navHeight = nav.offsetHeight
            chatList.style.maxHeight = `calc(100vh - ${navHeight}px)`
        }
    }

    useEffect(() => {
        if (userList && userList.length === 0) {
            let data = {
                pageNum: props.chat.currentPage === 0 ? 1 : props.chat.currentPage,
                sortBy: props.chat.sortBy === '' ? 'last_message_time' : props.chat.sortBy,
                userName: searchedUsername,
                sortBySubscribers: props.chat.sortBySubscribers === '' ? 'all' : props.chat.sortBySubscribers,
                isFilter: true
            }
            props.getUserList(data)
        }

        window.addEventListener('resize', updateChatInputHeight)
        return () => {
            window.removeEventListener('resize', updateChatInputHeight)
        }

    }, [])

    const handleScroll = (e) => {
        e.preventDefault()
        if (props.chat.totalPages > page) {
            let winScroll = 0
            let height = 0

            // Use the event target to get scrollTop and height
            winScroll = e.target.scrollTop
            height = e.target.scrollHeight - e.target.clientHeight
            const scrolled = winScroll / height
            if (scrolled > 0.98) {
                setPage(props.chat.currentPage + 1)
            }
        }
    }

    useEffect(() => {
        document.body.style.overflowY = 'hidden'
        const el = document.getElementById('chat-list')
        if (el) {
            el.addEventListener('scroll', handleScroll)
        }

        return () => {
            document.body.style.overflowY = 'auto'
            if (el) {
                el.removeEventListener('scroll', handleScroll)
            }
        }
    })

    useEffect(() => {
        if (page > 1) {
            setLoadMoreUser(true)
            const fetchData = async () => {
                let data = {
                    pageNum: page,
                    sortBy: props.chat.sortBy === '' ? 'last_message_time' : props.chat.sortBy,
                    userName: searchedUsername,
                    sortBySubscribers: props.chat.sortBySubscribers === '' ? 'all' : props.chat.sortBySubscribers,
                    isInfiniteScroll: true
                }
                await props.getUserList(data)
                setLoadMoreUser(false)
            }
            fetchData()
        }
    }, [page])

    useEffect(() => {
        if (props.match.params.id !== undefined && props.chat.selectedUserId === '') {
            props.setSelectedUserId(props.match.params.id, props.history)
            props.setIsChatScreenOpen(true)
        }
    })

    useEffect(() => {
        updateChatInputHeight()
        if (props.match.params.id !== undefined) {
            props.setSelectedUserId(props.match.params.id, props.history)
            props.setIsChatScreenOpen(true)
        }
    }, [selectedUserId])

    return (
        <div>
            <PageWrapper showFooter={false}>
                <WebsiteFontColor>
                    <ChatContainerDiv
                        backgroundColor={chatBackgroundColor}
                        className='container m-0 p-0'>
                        <div className='row m-0'>
                            <ChatListDiv
                                className={
                                    classNames('col-12 col-lg-4 col-xl-3 p-0 chat-list', {
                                        'd-none d-lg-block': isChatScreenOpen
                                    })
                                }
                                id='chat-list'
                                borderColor={chatBorderColor}
                                backgroundColor={chatUserListBackgroundColor}>
                                <ChatUserList
                                    colorScheme={colorScheme}
                                    setPage={setPage}
                                    loadMoreUser={loadMoreUser} />
                            </ChatListDiv>
                            {_.isEmpty(selectedUserId) === false &&
                                <MessageDiv
                                    id='message-list'
                                    chatBackgroundColor={chatBackgroundColor}
                                    className={classNames('col-12 col-lg-8 col-xl-9', {
                                        'd-none d-lg-block': !isChatScreenOpen
                                    })}>
                                    <Messages colorScheme={colorScheme} />
                                </MessageDiv>
                            }
                        </div>
                    </ChatContainerDiv>
                </WebsiteFontColor>
            </PageWrapper>
        </div>
    )
}

Chat.propTypes = {
    auth: PropTypes.object.isRequired,
    chat: PropTypes.object.isRequired,
    media: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    getUserList: PropTypes.func.isRequired,
    setSelectedUserId: PropTypes.func.isRequired,
    setSelectedChatModelId: PropTypes.func.isRequired,
    setIsChatScreenOpen: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
    auth: state.auth,
    chat: state.chat,
    media: state.media
})

export default connect(
    mapStateToProps,
    {
        getUserList,
        setSelectedUserId,
        setSelectedChatModelId,
        setIsChatScreenOpen
    }
)(withRouter(Chat))

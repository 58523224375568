import React, { useEffect, useState, useCallback } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import Janus from 'janus-js-core'
import StreamPublisher from './StreamPublisher'
import StreamSubscriber from './StreamSubscriber'
import { setStreamData } from '../../actions/streamAction'
import PropTypes from 'prop-types'

function StreamWrapper(props) {

    const initialRoom = props.room ? props.room : ''
    const initialPubId = props.pubId ? props.pubId : ''
    const initialPubPvtId = props.pubPvtId ? props.pubPvtId : ''
    const [janusInstance, setJanusInstance] = useState(null)
    const [loader, setLoader] = useState(true)
    const [goLiveLoader, setGoLiveLoader] = useState(true)
    const [room, setRoom] = useState(initialRoom)
    const [pubId, setPubId] = useState(initialPubId)
    const [pubPvtId, setPubPvtId] = useState(initialPubPvtId)
    const { streamToken } = props
    const { role } = props.auth.user
    const { content_color, live_streaming_server_url } = props.auth.appSettings

    // initialize janus func
    const handleJanusInit = useCallback(() => {
        Janus.init({
            debug: false,
            callback() {
                if (!Janus.isWebrtcSupported()) {
                    return
                }

                const janus = new Janus({
                    server: live_streaming_server_url + '/janus', // server url
                    token: streamToken,
                    iceServers: [
                        {
                            urls: 'turn:stun.l.google.com:19302',
                            username: 'janususer',
                            credential: 'januspwd'
                        }
                    ],
                    success() {
                        setJanusInstance(janus) // set janus to store for future usage
                        setLoader(false)
                    },
                    error(error) {
                        Janus.error(error)
                        // janus.destroy()

                        // setIsError(true)
                        // setErrorMessage(error)
                        // setIsLoading(false)
                        setJanusInstance(null)
                    },
                    destroyed() {
                        setJanusInstance(null)
                    }
                })
            }
        })
    }, [streamToken])


    useEffect(() => {
        if (streamToken) {
            handleJanusInit()
        }
    }, [streamToken, handleJanusInit])

    useEffect(() => {
        if (room !== '' && pubId !== '' && pubPvtId !== '' && (role === 'model' || role === 'live_stream_manager')) {
            const data = {
                room,
                pubId,
                pubPvtId
            }
            props.setStreamData(data, () => {
                setGoLiveLoader(false)
            })
        }
    }, [room, pubId, pubPvtId, props.isLoading])

    return (<>
        {(role === 'model' || role === 'live_stream_manager') &&
            <>
                {loader ? <></> :
                    <StreamPublisher
                        loader={goLiveLoader}
                        streamToken={streamToken}
                        janusInstance={janusInstance}
                        setRoom={setRoom}
                        setPubPvtId={setPubPvtId}
                        setPubId={setPubId}
                        onGoLive={props.onGoLive}
                        onStopLive={props.onStopLive}
                        tabToken={props.tabToken}
                        isGroupLive={props.isGroupLive}
                        setIsLoading={props.setIsLoading}
                        setLiveStreamStatus={props.setLiveStreamStatus}
                        setGoLiveLoader={setGoLiveLoader}
                    />
                }
            </>
        }
        {room !== '' && pubId !== '' && pubPvtId !== '' && role !== 'model' && role !== 'live_stream_manager' && janusInstance !== null &&
            <StreamSubscriber
                janusInstance={janusInstance}
                room={room}
                pubId={pubId}
                pubPvtId={pubPvtId}
                stopShowStream={props.stopShowStream}
                isGroupLive={props.isGroupLive}
            />
        }
    </>
    )
}

StreamWrapper.propTypes = {
    auth: PropTypes.object.isRequired,
    setStreamData: PropTypes.func.isRequired,
    streamToken: PropTypes.object.isRequired,
    room: PropTypes.number,
    pubId: PropTypes.number,
    pubPvtId: PropTypes.number,
    onGoLive: PropTypes.func,
    onStopLive: PropTypes.func,
    tabToken: PropTypes.string,
    stopShowStream: PropTypes.func,
    isGroupLive: PropTypes.bool
}

const mapStateToProps = state => ({
    auth: state.auth,
    admin: state.admin
})

export default connect(mapStateToProps, { setStreamData })(withRouter((StreamWrapper)))

import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import styled from 'styled-components'
import WebsiteFontColor from '../Layouts/WebsiteFontColor'
import { tipFromChat } from '../../actions/ccbillActions'
import { addNewMessageInEnd, sendTextMessage } from '../../actions/chatActions'
import { stickyIoTipFromChat } from '../../actions/stickyIoActions'
import _ from 'lodash'
import { TipPayment } from '../../actions/hybridPaymentAction'
import CryptoForm from '../crypto/CryptoForm'
import { updateWalletAmount, purchaseFromWallet } from '../../actions/cryptoPaymentActions'
import AddFundForm from '../crypto/AddFundForm'
import ConfirmSweetAlert from '../Layouts/ConfirmSweetAlert'
import { setConfirmSweetAlert, setSweetAlert } from '../../actions/sweetAlertActions'
import SweetAlertsWrapper from '../Layouts/SweetAlertsWrapper'

const InputButton = styled.button`
    background-color: ${props => props.buttonFontColor};
    color: ${props => props.buttonColor};
    height: 2.75rem;
    width: 2.75rem;
    border-radius: 50% !important;

    &:hover {
        background-color: ${props => props.buttonFontColor};
        color: ${props => props.buttonColor};
    }
`

const SendButtonDiv = styled.div`
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
`

const InputTextFieldDiv = styled.div`
    padding-right: 0 !important;
`

const SubmitButton = styled.button`
    border: none;
    background:transparent;

    ::focus{
        outline: none;
        box-shadow: none;
        border: none;
    }
`

function ChatInputOnLive(props) {
    const {
        content_color,
        content_font_color,
        card_background_color,
        site_font_color,
        tips_minimum_amount,
        tips_maximum_amount
    } = props.auth.appSettings
    const { isAdmin } = props.auth.user
    const { isLoading } = props.ccbill

    const [openInput, setOpenInput] = useState(false)
    const [amount, setAmount] = useState(5)
    const [messageText, setMessageText] = useState('')
    const [showAlert, setShowAlert] = useState(true)
    const [isTipByCrypto, setIsTipByCrypto] = useState(false)
    const [showAddFundPopup, setShowAddFundPopup] = useState(false)
    const [remainAmount, setRemainAmount] = useState(0)
    const [showConfirmAlert, setConfirmAlert] = useState(false)
    const [isMessageSending, setIsMessageSending] = useState(false)

    const InputModelOpen = () => {
        setOpenInput(true)
    }

    const InputModelClose = () => {
        setOpenInput(false)
    }

    const addMessage = (message) => {
        props.addNewMessageInEnd(message)
    }

    const sendTip = async () => {
        let amountValue = amount
        let amountInt = parseInt(amountValue, 10)
        let tipsMinimumAmount = parseInt(tips_minimum_amount, 10)
        let tipsMaximumAmount = parseInt(tips_maximum_amount, 10)

        if (!((tipsMinimumAmount <= amountInt) && (amountInt <= tipsMaximumAmount))) {
            props.setSweetAlert({ description: `Choose a tip between ${tipsMinimumAmount} and ${tipsMaximumAmount}` })
            setAmount(tipsMinimumAmount)
        } else {
            if (!/^[0-9]+(\.[0-9]{1,2})$/.test(amountValue)) {
                amountValue = amountValue + '.00'
            }

            let confirmMessage = `Please Confirm Tip of $${amountValue}.`
            if (props.auth.user.default_payment_method === 'crypto_currency') {
                confirmMessage += `You currently have $${props.auth.user.wallet_amount} in your wallet. Amount will be debited from your wallet balance.`
            }

            setAmount(amountValue)
            if (props.auth.user.default_payment_method === 'crypto_currency' && props.auth.user.wallet_amount < parseFloat(amount)) {
                processTipPayment()
            } else {
                setConfirmAlert(true)
                props.setConfirmSweetAlert({
                    description: confirmMessage
                })
            }
        }
    }

    const processTipPayment = async () => {
        let data = {
            amount: amount,
            recurring: false,
            email: props.auth.user.email,
            action: 'tips',
            tipFrom: 'go live stream'
        }
        if (props.auth.user.default_payment_method === 'crypto_currency') {
            tipUsingCrypto(props.auth.user.wallet_amount)
            // setIsTipByCrypto(true)
        } else {
            const payment_api_version = _.get(props.auth.appSettings, 'payment_api_version', 'v1')
            if (payment_api_version === 'v1') {
                if (props.auth.appSettings.is_sticky_io_enabled === true) {
                    props.stickyIoTipFromChat(data, props.auth.user._id, (res) => {
                        if (res.data.success === 0) {
                            props.setSweetAlert({ description: res.data.message })
                            setOpenInput(false)
                        } else {
                            addMessage(res.data.messageData)
                            setOpenInput(false)
                        }
                    })
                } else {
                    props.tipFromChat(data, props.auth.user._id, (res) => {
                        if (res.data.error) {
                            props.setSweetAlert({ description: res.data.error })
                            setOpenInput(false)
                        } else {
                            addMessage(res.data.messageData)
                            setOpenInput(false)
                        }
                    })
                }
            } else {
                const payment = await props.TipPayment(data, props.auth.user._id)
                setOpenInput(false)
                if (payment.success === 1) {
                    addMessage(payment.data.messageData)
                }
            }
        }
    }

    const tipUsingCrypto = async (walletBalance) => {
        const tipAmount = Number(parseFloat(amount).toFixed(2))
        if (walletBalance >= tipAmount) {
            const data = {
                payment_for: 'tips',
                amount: tipAmount.toString()
            }
            const res = await props.purchaseFromWallet(data)
            if (res.success === 1) {
                props.updateWalletAmount(res.data.wallet_balance)
                props.setSweetAlert({ description: res.data.message })
                setOpenInput(false)
            } else {
                const message = _.get(res, 'message', 'something went wrong')
                props.setSweetAlert({ description: message })
            }
        } else {
            const remainAmount = Math.ceil(tipAmount - walletBalance)
            if (walletBalance === 0.00) {
                setShowAddFundPopup(true)
                setRemainAmount(0)
            } else {
                setShowAddFundPopup(true)
                setRemainAmount(remainAmount)
            }
        }
    }

    const onTextMessageSend = (e) => {
        e.preventDefault()
        if (messageText.trim() === '') {
            return props.setSweetAlert({ description: 'Please type message.' })
        }
        if (messageText.trim().length > 1000) {
            return props.setSweetAlert({ description: 'Maximum 1000 characters allowed.' })
        }
        setIsMessageSending(true)
        const messageObject = {
            receiver: props.receiverId,
            type: 'text',
            fromAdmin: isAdmin,
            message: messageText
        }

        if (isAdmin === true) {
            messageObject.sender = props.auth.user._id
        }
        props.sendTextMessage(messageObject, (status) => {
            if (status === false) {
                props.history.push('profile/add-new-payment-method')
            }
            setMessageText('')
            setIsMessageSending(false)
        })
    }

    const onClickAlert = () => {
        setShowAlert(false)
        props.startVideoOnAlert()
    }

    const hideAddFundForm = () => {
        setShowAddFundPopup(false)
        setOpenInput(false)
    }

    return (
        <>
            <div className='row align-items-center justify-content-center mt-1'>
                <div className='col-12 col-md-auto w-100 px-3'>
                    <div className='row px-3'>
                        {isAdmin ?
                            <div className='col-12 col-sm-12 col-md'>
                                <form autoComplete='off' className='my-3'
                                    onSubmit={onTextMessageSend}
                                >
                                    <div className='row align-items-center justify-content-center'>
                                        <InputTextFieldDiv className='col'>
                                            <input
                                                className={'form-control'}
                                                type='text'
                                                onChange={(e) => setMessageText(e.target.value)}
                                                value={messageText}
                                                style={{ backgroundColor: 'transparent', border: '1px solid #ffffff85', color: '#fff' }}
                                                placeholder='Write a message...'
                                            />
                                        </InputTextFieldDiv>
                                        <SendButtonDiv className='col-auto'>
                                            <SubmitButton type='submit' disabled={(messageText === '' ? true : false) || (isMessageSending)}>
                                                <i
                                                    className='fas fa-paper-plane fa-2x pl-0'
                                                    style={{ cursor: 'pointer', color: messageText === '' ? '#ffffff85' : content_font_color }}
                                                    disabled={messageText === '' ? true : false}
                                                ></i>
                                            </SubmitButton>
                                        </SendButtonDiv>
                                    </div>
                                </form>
                            </div> :
                            <>
                                <div className='col-12 px-0'>
                                    <div className='input-group mb-3'>
                                        <div className='input-group-prepend'>
                                            <span className='pl-2' id='inputGroup-sizing-default'>
                                                <InputButton
                                                    className='btn my-2'
                                                    buttonFontColor='#0000004d'
                                                    buttonColor='white'
                                                    onClick={InputModelOpen}
                                                >
                                                    <i className="fas fa-dollar-sign"></i>
                                                </InputButton>
                                            </span>
                                        </div>
                                        <form autoComplete='off' className='my-3' style={{ display: 'flex', width: 'calc(100% - 67px)' }}
                                            onSubmit={onTextMessageSend}
                                        >
                                            <InputTextFieldDiv className='col'>
                                                <input
                                                    className={'form-control'}
                                                    type='text'
                                                    onChange={(e) => setMessageText(e.target.value)}
                                                    value={messageText}
                                                    style={{ backgroundColor: 'transparent', border: '1px solid #ffffff85', color: '#fff' }}
                                                    placeholder='Write a message...'
                                                />
                                            </InputTextFieldDiv>
                                            <div className='input-group-append'>
                                                <span className=''>
                                                    <SendButtonDiv className='col-auto'>
                                                        <SubmitButton type='submit' disabled={messageText === '' ? true : false}>
                                                            <i
                                                                className='fas fa-paper-plane fa-2x pl-0'
                                                                style={{ cursor: 'pointer', color: messageText === '' ? 'grey' : content_font_color }}
                                                            ></i>
                                                        </SubmitButton>
                                                    </SendButtonDiv>
                                                </span>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </>
                        }
                    </div>
                </div>
            </div>
            {
                openInput === true ?
                    <WebsiteFontColor>
                        <div className='modal fade show' role='dialog' style={{ display: 'block', backgroundColor: '#00000080' }}>
                            <div className='modal-dialog modal-dialog-centered' role='document'>
                                <div className='modal-content'>
                                    <div className='modal-header' style={{ backgroundColor: card_background_color }}>
                                        <h5 className='modal-title'>Send Tip</h5>
                                        <button className='close' disabled={isLoading} onClick={InputModelClose} style={{ color: site_font_color, opacity: 1 }} />
                                    </div>
                                    <div className='modal-body' style={{ backgroundColor: card_background_color }}>
                                        <div className='container'>
                                            <div className='form-row'>
                                                <div className='form-group col'>
                                                    <div className='input-group'>
                                                        <div className='input-group-prepend'>
                                                            <span className='input-group-text form-control'>$</span>
                                                        </div>
                                                        <input
                                                            type='text'
                                                            id='amount'
                                                            name='amount'
                                                            className='form-control'
                                                            value={amount}
                                                            onChange={(e) => setAmount(e.target.value)}
                                                            min={tips_minimum_amount}
                                                            max={tips_maximum_amount}
                                                            disabled={isLoading}
                                                        />
                                                    </div>
                                                    <small id='navbar-font-weight-help' className='form-text text-muted'>
                                                        Minimum ${tips_minimum_amount} USD.
                                                        <br />
                                                        {props.auth.user.default_payment_method === 'crypto_currency' ?
                                                            <>
                                                                ${amount} will be debited from wallet balance.
                                                            </>
                                                            :
                                                            <>
                                                                ${amount} will be charged to the payment method you used previously on the website.
                                                            </>
                                                        }
                                                    </small>
                                                </div>
                                            </div>
                                            <div className='row'>
                                                <div className='col'>
                                                    <button type='button' className='btn btn-block'
                                                        onClick={sendTip}
                                                        style={{ width: '100%', backgroundColor: content_color, color: content_font_color }}
                                                        disabled={isLoading}
                                                    >
                                                        Send a tip of ${amount}
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </WebsiteFontColor>
                    : null
            }
            {
                showAlert === true && props.auth.user.isAdmin === false ?
                    <WebsiteFontColor>
                        <div className='modal fade show' role='dialog' style={{ display: 'block', backgroundColor: '#00000080' }}>
                            <div className='modal-dialog modal-dialog-centered' role='document'>
                                <div className='modal-content'>
                                    <div className='modal-body' style={{ backgroundColor: card_background_color }}>
                                        <div className='container'>
                                            <div className='row'>
                                                <div className='col-12 text-center'>
                                                    <p>{props.receiverProfileDetails} has started the Go Live Stream.  Be sure to turn on the volume using the sound icon at the top of the screen when you are ready.</p>
                                                </div>
                                            </div>
                                            <div className='row'>
                                                <div className='col'>
                                                    <button type='button' className='btn btn-block'
                                                        onClick={onClickAlert}
                                                        style={{ width: '100%', backgroundColor: content_color, color: content_font_color }}
                                                    >
                                                        Ok
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </WebsiteFontColor>
                    : null
            }
            {isTipByCrypto === true &&
                <div className='modal fade show' role='dialog' style={{ display: 'block', backgroundColor: '#00000080' }}>
                    <div className='modal-dialog modal-dialog-centered modal-dialog-scrollable' role='document'>
                        <div className='modal-content' style={{ backgroundColor: card_background_color }}>
                            <div className='modal-header align-items-center'>
                                <button className='close' onClick={() => {
                                    setIsTipByCrypto(false)
                                }} style={{ color: site_font_color, opacity: 1 }} />
                            </div>
                            <div className='modal-body'>
                                <div className='container'>
                                    <CryptoForm
                                        onHideAddFundsForm={props.onHideAddFund}
                                        type='tips'
                                        tipFrom='go live stream'
                                        isAnonymousTip='false'
                                        amount={amount.toString()}
                                        onCompleteTransaction={() => {
                                            setIsTipByCrypto(false)
                                            setOpenInput(false)
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
            {showAddFundPopup === true &&
                <div className='modal fade show' role='dialog' style={{ display: 'block', backgroundColor: '#00000080' }}>
                    <div className='modal-dialog modal-dialog-centered modal-dialog-scrollable' role='document'>
                        <div className='modal-content' style={{ backgroundColor: card_background_color }}>
                            <div className='modal-header align-items-center'>
                                <button className='close' onClick={() => {
                                    setShowAddFundPopup(false)
                                    setRemainAmount(0)
                                }} style={{ color: site_font_color, opacity: 1 }} />
                            </div>
                            <div className='modal-body'>
                                <div className='container'>
                                    <AddFundForm
                                        onHideAddFund={hideAddFundForm}
                                        type='tips'
                                        transactionAmount={Number(amount)}
                                        remainAmount={remainAmount}
                                        onCompleteTransaction={
                                            (updatedBalance) => {
                                                setShowAddFundPopup(false)
                                                if (updatedBalance) {
                                                    tipUsingCrypto(updatedBalance)
                                                }
                                            }
                                        }
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
            {showConfirmAlert && <ConfirmSweetAlert onConfirm={() => { processTipPayment(); setConfirmAlert(false) }} onCancel={() => setConfirmAlert(false)} />}
            <SweetAlertsWrapper />
        </>
    )
}

ChatInputOnLive.propTypes = {
    auth: PropTypes.object.isRequired,
    tipFromChat: PropTypes.func.isRequired,
    addNewMessageInEnd: PropTypes.func.isRequired,
    sendTextMessage: PropTypes.func.isRequired,
    receiverId: PropTypes.string.isRequired,
    startVideoOnAlert: PropTypes.func.isRequired,
    match: PropTypes.object.isRequired,
    receiverProfileDetails: PropTypes.string.isRequired,
    ccbill: PropTypes.object.isRequired,
    stickyIoTipFromChat: PropTypes.func.isRequired,
    TipPayment: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
    purchaseFromWallet: PropTypes.func.isRequired,
    updateWalletAmount: PropTypes.func.isRequired,
    onHideAddFund: PropTypes.func,
    setConfirmSweetAlert: PropTypes.func.isRequired,
    setSweetAlert: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
    auth: state.auth,
    ccbill: state.ccbill
})

export default connect(mapStateToProps, {
    tipFromChat,
    addNewMessageInEnd,
    sendTextMessage,
    stickyIoTipFromChat,
    TipPayment,
    purchaseFromWallet,
    updateWalletAmount,
    setConfirmSweetAlert,
    setSweetAlert
})(withRouter(ChatInputOnLive))

import React, { useEffect, useState, useRef } from 'react'
import styled from 'styled-components'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import SendRoundedIcon from '@material-ui/icons/SendRounded'
import ChatInput from './ChatInput'
import { sendTextMessage, setSelectedChatModelId } from '../../actions/chatActions'
import { addPayPerMessageCredit, removePayPerMessageCredit } from '../../actions/authActions'
import ChatInputHandler from './ChatInputHandler'
import { setSweetAlert } from '../../actions/sweetAlertActions'

const MainDiv = styled.div`
    background: ${props => props.backgroundColor};
    border-top: 1px solid ${props => props.borderColor};
    z-index:  ${props => props.isShowInput ? '1' : '2'} !important;
    position: sticky !important;
    padding: env(safe-area-inset-top, 0.25rem) env(safe-area-inset-right, 0px) env(safe-area-inset-bottom, 0.25rem) env(safe-area-inset-left, 0px) !important;

    @media(max-width: 576px){
        position: fixed !important;
        bottom: 0 !important;
        width: 100%;
    }
`

const InputButton = styled.button`
    padding: 0 !important;
    background-color: ${props => props.buttonColor};
    color: ${props => props.buttonFontColor};
    height: 34px;
    min-width: 34px !important;
    width: 34px !important;
    border-radius: 50% !important;
    box-shadow: none;

    &:hover {
        color: ${props => props.buttonFontColor};
        box-shadow: none;
    }
`

const ChatInputSection = (props) => {

    const { auth, chat, colorScheme } = props
    const { user, appSettings } = auth
    const { showBootstrapNavbar } = chat
    const {
        chatInputSectionBackgroundColor,
        chatButtonBackgroundColor,
        chatButtonFontColor,
        chatBorderColor
    } = colorScheme

    const {
        isAdmin,
        role
    } = user
    const [message, setMessage] = useState('')
    const [loading, setLoading] = useState(false)
    const [inputType, setInputType] = useState('')
    const textareaRef = useRef(null)

    const resetForm = () => {
        setMessage('')
        setLoading(false)
    }


    // Extrasss
    async function sendTip(amount, tipMessage, callback) {
        // let amountInt = parseInt(amount, 10)
        // let tipsMinimumAmount = parseInt(this.props.auth.appSettings.tips_minimum_amount, 10)
        // let tipsMaximumAmount = parseInt(this.props.auth.appSettings.tips_maximum_amount, 10)

        // if (!((tipsMinimumAmount <= amountInt) && (amountInt <= tipsMaximumAmount))) {
        //     const message = `Choose a tip between ${tipsMinimumAmount} and ${tipsMaximumAmount}`
        //     props.setSweetAlert({ description: message })
        //     this.setState({ amount: tipsMinimumAmount })
        // } else {
        //     if (!/^[0-9]+(\.[0-9]{1,2})$/.test(amount)) {
        //         amount = amount + '.00'
        //     }
        //     // Set google analytics add_to_cart event for tip
        //     googleAnalyticsTrackEvent('add_to_cart', '', amount, 'chat', 'tip', '')
        //     let confirmMessage = `Please Confirm Tip of $${amount}.`
        //     if (this.props.auth.user.default_payment_method === 'crypto_currency') {
        //         confirmMessage += `You currently have $${this.props.auth.user.wallet_amount} in your wallet. Amount will be debited from your wallet balance.`
        //     }

        //     let confirmation
        //     if (this.props.auth.user.default_payment_method !== 'crypto_currency') {
        //         confirmation = window.confirm(confirmMessage)
        //     } else {
        //         if (this.props.auth.user.wallet_amount >= parseFloat(amount)) {
        //             confirmation = window.confirm(confirmMessage)
        //         } else {
        //             confirmation = true
        //         }
        //     }

        //     if (confirmation) {
        //         this.setState({ tipAmount: amount })
        //         let data = {
        //             amount: amount,
        //             recurring: false,
        //             email: this.props.auth.user.email,
        //             action: 'tips',
        //             tipFrom: 'chat',
        //             tipMessage: tipMessage
        //         }
        //         if (this.props.auth.user.default_payment_method === 'crypto_currency') {
        //             this.tipUsingCrypto(amount, this.props.auth.user.wallet_amount, tipMessage, (res) => {
        //                 callback(res)
        //             })
        //         } else {
        //             const payment_api_version = _.get(this.props.auth.appSettings, 'payment_api_version', 'v1')
        //             if (payment_api_version === 'v1') {
        //                 if (this.props.auth.appSettings.is_sticky_io_enabled === true) {
        //                     this.props.stickyIoTipFromChat(data, this.props.auth.user._id, (res) => {
        //                         if (res.data.success === 0) {
        //                             props.setSweetAlert({ description: res.data.message })
        //                         } else {
        //                             this.addMessage(res.data.messageData)
        //                             this.scrollToBottom()
        //                         }
        //                         callback(res)
        //                     })
        //                 } else {
        //                     this.props.tipFromChat(data, this.props.auth.user._id, (res) => {
        //                         if (res.data.error) {
        //                             props.setSweetAlert({ description: res.data.error })
        //                         } else {
        //                             this.addMessage(res.data.messageData)
        //                             this.scrollToBottom()
        //                         }
        //                         callback(res)
        //                     })
        //                 }
        //             } else {
        //                 const payment = await this.props.TipPayment(data, this.props.auth.user._id)
        //                 payment.show_alert = false
        //                 if (payment.success === 1) {
        //                     this.addMessage(payment.data.messageData)
        //                     this.scrollToBottom()
        //                 }
        //                 callback(payment)
        //             }
        //         }
        //     }
        // }
    }

    function scrollToBottom() {
        // get the messageList container and set the scrollTop to the height of the container
        const objDiv = document.getElementById('message-list')
        if (objDiv) {
            objDiv.scrollTop = objDiv.scrollHeight
        }
    }

    function payPerMessageCreditRemove() {
        if (
            auth.appSettings.is_pay_per_message_enabled === true &&
            auth.user.payPerMessageCredit > 0
        ) {
            props.removePayPerMessageCredit()
        }
    }

    function payPerMessageCreditAdd() {
        props.addPayPerMessageCredit()
    }

    function payPerMessageCryptoPayment(data) {
        // TODO: handle below thing...
        // this.setState({
        //     showAddFundPopup: data.showAddFundPopup,
        //     remainAmount: data.remainAmount,
        //     cryptoPaymentType: 'chat',
        //     tipAmount: data.tipAmount
        // })
    }

    // Extrasss

    const sendMessage = (data, callback) => {
        props.sendTextMessage(data, (status) => {
            if (status === false) {
                // props.history.push('profile/add-new-payment-method')
            } else {
                callback()
            }
        })
    }

    const handleTextMessage = () => {
        const data = {
            sender: appSettings.model_id,
            receiver: chat.selectedUserId,
            type: 'text',
            fromAdmin: true,
            message: message
        }
        setLoading(true)
        sendMessage(data, () => {
            scrollToBottom()
            resetForm()
        })
    }

    const handleMessage = () => {
        const messageType = 'text'
        if (message === '' && messageType === 'text') {
            const message = 'Message can\'t be empty'
            return props.setSweetAlert({ description: message })
        }
        // TODO: find message type
        if (isAdmin) {
            if (messageType === 'text') {
                if (textareaRef && textareaRef.current) {
                    textareaRef.current.focus()
                }
                handleTextMessage()
                return
            }
        }
    }

    useEffect(() => {
        props.setSelectedChatModelId(auth.appSettings.model_id)
    }, [])

    return (
        <>
            <MainDiv
                id='input-section'
                className='d-flex align-items-end bottom-0 left-0'
                backgroundColor={chatInputSectionBackgroundColor}
                borderColor={chatBorderColor}
                isShowInput={showBootstrapNavbar}
            >
                <div className='py-1'>
                    <InputButton
                        className='btn m-0 mx-1'
                        buttonColor={chatButtonBackgroundColor}
                        buttonFontColor={chatButtonFontColor}
                        onClick={() => setInputType('photo')}
                        disabled={loading}
                    >
                        <i className='fas fa-images'></i>
                    </InputButton>
                    {isAdmin === false &&
                        <InputButton
                            className='btn m-0 mr-1'
                            buttonColor={chatButtonBackgroundColor}
                            buttonFontColor={chatButtonFontColor}
                            onClick={() => setInputType('tip')}
                            disabled={loading}
                        >
                            <i className='fas fa-dollar-sign'></i>
                        </InputButton>
                    }
                </div>
                <div
                    className='flex-fill d-flex align-items-center py-1'>
                    <ChatInput
                        textareaRef={textareaRef}
                        auth={props.auth}
                        value={message}
                        setValue={setMessage}
                        disabled={false}
                        borderColor={chatButtonBackgroundColor}
                    />
                </div>
                <div className='mx-1 py-1'>
                    <InputButton
                        className='btn m-0 p-0'
                        disabled={loading}
                        onClick={handleMessage}
                        buttonColor={chatButtonBackgroundColor}
                        buttonFontColor={chatButtonFontColor}
                    >
                        <SendRoundedIcon style={{ padding: '3px', margin: '1px 5px' }} />
                    </InputButton>
                </div>
            </MainDiv>
            {role !== 'live_stream_manager' &&
                <ChatInputHandler
                    onSend={handleMessage}
                    isAdmin={isAdmin}
                    sendTip={sendTip}
                    scrollToBottom={scrollToBottom}
                    payPerMessageCreditRemove={payPerMessageCreditRemove}
                    payPerMessageCreditAdd={payPerMessageCreditAdd}
                    payPerMessageCryptoPayment={payPerMessageCryptoPayment}
                    inputType={inputType}
                    setInputType={setInputType}
                    message={message}
                    setMessage={setMessage}
                />
            }
        </>
    )
}

ChatInputSection.propTypes = {
    auth: PropTypes.object.isRequired,
    chat: PropTypes.object.isRequired,
    media: PropTypes.object.isRequired,
    sendTextMessage: PropTypes.func.isRequired,
    removePayPerMessageCredit: PropTypes.func.isRequired,
    addPayPerMessageCredit: PropTypes.func.isRequired,
    setSelectedChatModelId: PropTypes.func.isRequired,
    setSweetAlert: PropTypes.func.isRequired,
    colorScheme: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
    auth: state.auth,
    chat: state.chat,
    media: state.media,
    liveStream: state.liveStream
})

export default connect(
    mapStateToProps,
    {
        sendTextMessage,
        removePayPerMessageCredit,
        addPayPerMessageCredit,
        setSelectedChatModelId,
        setSweetAlert

    }
)(withRouter(ChatInputSection))

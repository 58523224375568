import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { toggleNewPaymentModal } from '../../actions/utilityActions'
import { chargeByPrevious } from '../../actions/ccbillActions'
import { loadMore } from '../../actions/chatActions'
import { getCloudFrontAssetsUrl } from '../../utils/assets'

const CommentDisplayStyle = styled.div`
    width: 100%;
    max-height: ${props => props.isStreamPublisher === true ? '400px' : '35%'};
    overflow-y: scroll !important;
    -webkit-overflow-scrolling: touch;
    position: fixed;
    bottom: ${props => props.role === 'live_stream_manager' ? '0' : '70px'};
    width: 100%;
    scrollbar-color: auto;
    scrollbar-width: thin;
    max-width: 100%;
    display: flex;
    flex-direction: column-reverse;

    .main_division {
        position: relative;
        word-break: break-word;
    }
`

function LiveStreamGroupMessages(props) {

    const [modelName, setModelName] = useState('')

    const { _id, isAdmin, role } = props.auth.user
    const { model_id } = props.auth.appSettings

    useEffect(() => {
        if (props.chat.userProfileInfo[model_id] !== undefined) {
            setModelName(props.chat.userProfileInfo[model_id].name)
        }
    }, [])

    const renderMessage = (message) => {
        if (message.type === 'text') {
            return message.message
        } else if (message.type === 'tips') {
            let backgroundColor = 'rgb(34 174 5)'
            if ((role === 'model' || role === 'live_stream_manager') && message.from_admin === true) {
                backgroundColor = 'grey'
            }
            if (_id === message.sender_id) {
                if (message.is_anonymous === true) {
                    return (
                        <>
                            <b style={{ backgroundColor: backgroundColor }} className='d-inline-block mt-1 p-1 px-3 rounded-pill'>You just tipped {modelName} ${message.amount} anonymously!</b>
                        </>)
                } else {
                    return (
                        <>
                            <b style={{ backgroundColor: backgroundColor }} className='d-inline-block mt-1 p-1 px-3 rounded-pill'>You just tipped {modelName} ${message.amount}!</b>
                        </>)
                }
            } else if (role === 'model' || role === 'live_stream_manager') {
                return (
                    <>
                        <b style={{ backgroundColor: backgroundColor }} className='d-inline-block mt-1 p-1 px-3 rounded-pill'>just tipped you ${message.amount}!</b>
                    </>)
            } else {
                return (
                    <>
                        <b style={{ backgroundColor: backgroundColor }} className='d-inline-block mt-1 p-1 px-3 rounded-pill'>just tipped ${message.amount}</b>
                    </>)
            }
        }
    }
    const scrollToBottom = () => {
        // get the messageLiveList div and set the scrollTop to the height of the div
        const objDiv = document.getElementById('messageLiveList')
        if (objDiv) {
            objDiv.scrollTop = objDiv.scrollHeight
        }
    }

    useEffect(() => {
        scrollToBottom()
    }, [props.liveStream])

    return (
        <CommentDisplayStyle
            isStreamPublisher={props.isPublisher}
            className='px-3 stretched-link' id='messageLiveList'
            role={role}
        >
            {props.liveStream.groupMessages.map((message, index) => {
                return (<div key={index} className='main_division'>
                    {message.type === 'system' ?
                        <div className='mb-2 text-center'>
                            <div className='card-text'>
                                <div className='row align-items-center px-3'>
                                    <div className='col-12 muted' style={{ maxWidth: '100%' }}>
                                        {renderMessage(message)}
                                    </div>
                                </div>
                            </div>
                        </div>
                        :
                        <>
                            {(_id === message.sender_id) ?
                                <div className='mb-2 text-right pl-sm-5'>
                                    <div className='card-text'>
                                        <div className='row align-items-center justify-content-end px-3'>
                                            <div className='col-sm-12 col-12 pr-0 pl-5'>
                                                <div className='row pr-7'>
                                                    <img src={message.image ? message.image : getCloudFrontAssetsUrl('faces/avatar.png')} alt='img' style={{ height: '34px', position: 'absolute', right: '0', width: '34px', borderRadius: '50%', margin: '5px auto', padding: '0px' }} />
                                                    <div className='col-12 muted'>
                                                        {message.name}
                                                    </div>
                                                    <div className='col-12 text-bold'>
                                                        {renderMessage(message)}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div> :
                                <div className='mb-2'>
                                    <div className='card-text text-left pr-sm-5'>
                                        <div className='row align-items-center px-3'>
                                            <div className='col-sm-12 col-12 pl-0 pr-5'>
                                                <div className='row pl-7'>
                                                    <img src={message.image ? isAdmin || message.is_anonymous !== true ? message.image : getCloudFrontAssetsUrl('faces/avatar.png') : getCloudFrontAssetsUrl('faces/avatar.png')} alt='img' style={{ height: '34px', position: 'absolute', left: '0', width: '34px', borderRadius: '50%', margin: '5px auto', padding: '0px' }} />
                                                    <div className='col-12'>
                                                        <div className='muted'>{isAdmin === false && message.is_anonymous === true ? 'Anonymous' : message.name}</div>
                                                    </div>
                                                    <div className='col-12 text-bold'>
                                                        {renderMessage(message)}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                        </>
                    }
                </div>
                )
            })
            }
        </CommentDisplayStyle>
    )
}

LiveStreamGroupMessages.propTypes = {
    chat: PropTypes.object.isRequired,
    auth: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    toggleNewPaymentModal: PropTypes.func.isRequired,
    chargeByPrevious: PropTypes.func.isRequired,
    loadMore: PropTypes.func.isRequired,
    isPublisher: PropTypes.bool.isRequired,
    liveStream: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
    chat: state.chat,
    auth: state.auth,
    liveStream: state.liveStream
})

export default connect(mapStateToProps, { toggleNewPaymentModal, chargeByPrevious, loadMore })(withRouter(LiveStreamGroupMessages))
